body {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	color: #2c3e50;
	background-color: #ecf0f1;
}
body h1 {
	font-size: 2.5rem;
	color: #000000;
}
body h2 {
	font-size: 2rem;
	color: #000000;
}
body .logo {
	background-image: url("./../images/logo.png");
}

/* Extra Large (XL) Devices */
@media (min-width: 1200px) {
	.align-content-xl-center {
		align-content: center;
	}
	.align-content-xl-start {
		align-content: start;
	}
	.align-content-xl-end {
		align-content: end;
	}
	.margin-auto-xl {
		margin: 0 auto;
	}
}
/* Large (LG) Devices */
@media (max-width: 1199.98px) {
	.align-content-lg-center {
		align-content: center;
	}
	.align-content-lg-start {
		align-content: start;
	}
	.align-content-lg-end {
		align-content: end;
	}
	.margin-auto-lg {
		margin: 0 auto;
	}
}
/* Medium (MD) Devices */
@media (max-width: 991.98px) {
	.align-content-md-center {
		align-content: center;
	}
	.align-content-md-start {
		align-content: start;
	}
	.align-content-md-end {
		align-content: end;
	}
	.margin-auto-md {
		margin: 0 auto;
	}
}
/* Small (SM) Devices */
@media (max-width: 767.98px) {
	.align-content-sm-center {
		align-content: center;
	}
	.align-content-sm-start {
		align-content: start;
	}
	.align-content-sm-end {
		align-content: end;
	}
	.margin-auto-sm {
		margin: 0 auto;
	}
}
/* Extra Small (XS) Devices */
@media (max-width: 575.98px) {
	.align-content-xs-center {
		align-content: center;
	}
	.align-content-xs-start {
		align-content: start;
	}
	.align-content-xs-end {
		align-content: end;
	}
	.margin-auto-xs {
		margin: 0 auto;
	}
}
.hero-techBG {
	background: url("https://www.spoofguard.io/static/media/SG_landing_woman3.a5319a00c21dd075c229.webp")
		no-repeat center center;
	background-size: cover;
	padding: 50px 20px;
	text-align: left;
	color: #333333;
}

.hero-techBG .hero-content-techBG h1 {
	font-size: 2.5rem;
	margin-bottom: 20px;
}

.hero-techBG .hero-content-techBG .buttons {
	display: flex;
	justify-content: left;
	gap: 20px;
}
.hero-techBG .hero-content-techBG .buttons .demo-button,
.hero-techBG .hero-content-techBG .buttons .trial-button {
	padding: 10px 20px;
	font-size: 1rem;
	border: none;
	border-radius: 5px;
	background-color: #007bff;
	color: #ffffff;
	cursor: pointer;
}

@media (max-width: 768px) {
	.hero {
		padding: 30px 10px;
	}
	.hero .hero-content h1 {
		font-size: 2rem;
	}
	.hero .hero-content p {
		font-size: 0.9rem;
	}
	.hero .hero-content .buttons {
		flex-direction: column;
	}
	.hero .hero-content .buttons .demo-button,
	.hero .hero-content .buttons .trial-button {
		width: 100%;
		margin-bottom: 10px;
	}
}
@media (max-width: 480px) {
	.hero {
		padding: 20px 5px;
	}
	.hero .hero-content h1 {
		font-size: 1.5rem;
	}
	.hero .hero-content p {
		font-size: 0.8rem;
	}
	.hero .hero-content .buttons .demo-button,
	.hero .hero-content .buttons .trial-button {
		font-size: 0.9rem;
	}
}
section.technology h1,
.technology h1,
section.security-section h1,
.security-section h1 {
	color: #000000;
}
section.technology h2,
.technology h2,
section.security-section h2,
.security-section h2 {
	color: #000000;
}
section.technology .how div.icon svg,
.technology .how div.icon svg,
section.security-section .how div.icon svg,
.security-section .how div.icon svg {
	margin: 20px auto 10px auto;
}
section.technology .accordion .accordion-item,
.technology .accordion .accordion-item,
section.security-section .accordion .accordion-item,
.security-section .accordion .accordion-item {
	border-bottom: 1px solid #000;
}
section.technology .accordion .accordion-item .accordion-title,
.technology .accordion .accordion-item .accordion-title,
section.security-section .accordion .accordion-item .accordion-title,
.security-section .accordion .accordion-item .accordion-title {
	padding: 15px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bold;
}
section.technology .accordion .accordion-item .accordion-title:hover,
.technology .accordion .accordion-item .accordion-title:hover,
section.security-section .accordion .accordion-item .accordion-title:hover,
.security-section .accordion .accordion-item .accordion-title:hover {
	background-color: #e9e9e9;
}
section.technology .accordion .accordion-item .accordion-title.active .arrow,
.technology .accordion .accordion-item .accordion-title.active .arrow,
section.security-section
	.accordion
	.accordion-item
	.accordion-title.active
	.arrow,
.security-section .accordion .accordion-item .accordion-title.active .arrow {
	transform: rotate(180deg);
}
section.technology .accordion .accordion-item .accordion-title .arrow,
.technology .accordion .accordion-item .accordion-title .arrow,
section.security-section .accordion .accordion-item .accordion-title .arrow,
.security-section .accordion .accordion-item .accordion-title .arrow {
	transition: transform 0.3s ease;
}
section.technology .accordion .accordion-item .accordion-content,
.technology .accordion .accordion-item .accordion-content,
section.security-section .accordion .accordion-item .accordion-content,
.security-section .accordion .accordion-item .accordion-content {
	padding: 15px;
	display: none;
}
section.technology .accordion .accordion-item .accordion-content p,
.technology .accordion .accordion-item .accordion-content p,
section.security-section .accordion .accordion-item .accordion-content p,
.security-section .accordion .accordion-item .accordion-content p {
	margin: 0;
}
section.technology .request-part .hero,
.technology .request-part .hero,
section.security-section .request-part .hero,
.security-section .request-part .hero {
	background: url("./../images/tech-bg-dark.webp") no-repeat center center;
	background-size: cover;
	padding: 20px 10px;
	text-align: center;
	color: #ffffff;
	min-height: 360px;
	height: 470px;
}
section.technology .request-part .hero .hero-content,
.technology .request-part .hero .hero-content,
section.security-section .request-part .hero .hero-content,
.security-section .request-part .hero .hero-content {
	max-width: 100%;
	margin: 0 auto;
}
section.technology .request-part .hero .hero-content h1,
.technology .request-part .hero .hero-content h1,
section.security-section .request-part .hero .hero-content h1,
.security-section .request-part .hero .hero-content h1 {
	font-size: 2.5rem;
	margin-bottom: 20px;
	color: #ffffff;
}
section.technology .request-part .hero .hero-content p,
.technology .request-part .hero .hero-content p,
section.security-section .request-part .hero .hero-content p,
.security-section .request-part .hero .hero-content p {
	font-size: 1.2rem;
	margin-bottom: 20px;
}
section.technology .request-part .hero .hero-content form,
.technology .request-part .hero .hero-content form,
section.security-section .request-part .hero .hero-content form,
.security-section .request-part .hero .hero-content form {
	display: flex;
	justify-content: center;
	align-items: center;
}
section.technology .request-part .hero .hero-content form input,
section.technology .request-part .hero .hero-content form input.tech-request,
.technology .request-part .hero .hero-content form input,
.technology .request-part .hero .hero-content form input.tech-request,
section.security-section .request-part .hero .hero-content form input,
section.security-section
	.request-part
	.hero
	.hero-content
	form
	input.tech-request,
.security-section .request-part .hero .hero-content form input,
.security-section .request-part .hero .hero-content form input.tech-request {
	padding: 10px;
	font-size: 1rem;
	border: none;
	border-radius: 5px 0 0 5px;
	width: 100%;
	background: none;
	border: 2px solid #ffffff;
}
section.technology
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::-moz-placeholder,
.technology
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::-moz-placeholder,
section.security-section
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::-moz-placeholder,
.security-section
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::-moz-placeholder {
	color: #ffffff !important;
	opacity: 1 !important;
	margin: auto;
}
section.technology
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::placeholder,
.technology
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::placeholder,
section.security-section
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::placeholder,
.security-section
	.request-part
	.hero
	.hero-content
	form
	input.tech-request::placeholder {
	color: #ffffff !important;
	opacity: 1 !important;
	margin: auto;
}
section.technology .request-part .hero .hero-content form button,
.technology .request-part .hero .hero-content form button,
section.security-section .request-part .hero .hero-content form button,
.security-section .request-part .hero .hero-content form button {
	padding: 10px 20px;
	font-size: 1rem;
	border: none;
	border-radius: 0 5px 5px 0;
	background-color: #000000;
	color: #ffffff;
	margin: auto;
	max-height: 100%;
	height: 50px;
	font-size: 18px;
	border-radius: 4px !important;
	background-color: #30afee;
	border: 1px solid #30afee;
	color: white;
	width: auto;
	cursor: pointer;
	min-width: -moz-fit-content;
	min-width: fit-content;
	margin: 0 0 0 15px;
}
@media screen and (max-width: 768px) {
	section.technology .request-part .hero .hero-content form button,
	.technology .request-part .hero .hero-content form button,
	section.security-section .request-part .hero .hero-content form button,
	.security-section .request-part .hero .hero-content form button {
		margin: auto;
	}
}
section.technology .smarter-way,
.technology .smarter-way,
section.security-section .smarter-way,
.security-section .smarter-way {
	box-shadow: 0px 10px 3rem -8px rgba(0, 0, 0, 0.3);
	padding: 62px 0;
}
section.technology .smarter-way a,
.technology .smarter-way a,
section.security-section .smarter-way a,
.security-section .smarter-way a {
	margin: 0;
	display: inline-block;
	margin-right: 20px;
}
section.technology .contact-us-section .contact-us-content,
.technology .contact-us-section .contact-us-content,
section.security-section .contact-us-section .contact-us-content,
.security-section .contact-us-section .contact-us-content {
	padding: 0;
	margin-top: auto;
	padding-bottom: 70px;
}
section.technology .contact-us-section .contact-us-content .contact-us-right h2,
section.technology
	.contact-us-section
	.contact-us-content
	.contact-us-right
	h2.contact-title,
section.technology
	.contact-us-section
	.contact-us-content
	.contact-us-right
	.contact-title,
.technology .contact-us-section .contact-us-content .contact-us-right h2,
.technology
	.contact-us-section
	.contact-us-content
	.contact-us-right
	h2.contact-title,
.technology
	.contact-us-section
	.contact-us-content
	.contact-us-right
	.contact-title,
section.security-section
	.contact-us-section
	.contact-us-content
	.contact-us-right
	h2,
section.security-section
	.contact-us-section
	.contact-us-content
	.contact-us-right
	h2.contact-title,
section.security-section
	.contact-us-section
	.contact-us-content
	.contact-us-right
	.contact-title,
.security-section .contact-us-section .contact-us-content .contact-us-right h2,
.security-section
	.contact-us-section
	.contact-us-content
	.contact-us-right
	h2.contact-title,
.security-section
	.contact-us-section
	.contact-us-content
	.contact-us-right
	.contact-title {
	margin-bottom: revert;
}
@media (max-width: 768px) {
	section.technology .hero,
	.technology .hero,
	section.security-section .hero,
	.security-section .hero {
		padding: 30px 10px;
	}
	section.technology .hero .hero-content h1,
	.technology .hero .hero-content h1,
	section.security-section .hero .hero-content h1,
	.security-section .hero .hero-content h1 {
		font-size: 2rem;
	}
	section.technology .hero .hero-content p,
	.technology .hero .hero-content p,
	section.security-section .hero .hero-content p,
	.security-section .hero .hero-content p {
		font-size: 1rem;
	}
	section.technology .hero .hero-content form,
	.technology .hero .hero-content form,
	section.security-section .hero .hero-content form,
	.security-section .hero .hero-content form {
		flex-direction: column;
	}
	section.technology .hero .hero-content form input,
	.technology .hero .hero-content form input,
	section.security-section .hero .hero-content form input,
	.security-section .hero .hero-content form input {
		width: 100%;
		margin-bottom: 10px;
		border-radius: 5px;
	}
	section.technology .hero .hero-content form button,
	.technology .hero .hero-content form button,
	section.security-section .hero .hero-content form button,
	.security-section .hero .hero-content form button {
		width: 100%;
		border-radius: 5px;
	}
}
@media (max-width: 480px) {
	section.technology .hero,
	.technology .hero,
	section.security-section .hero,
	.security-section .hero {
		padding: 20px 5px;
	}
	section.technology .hero .hero-content h1,
	.technology .hero .hero-content h1,
	section.security-section .hero .hero-content h1,
	.security-section .hero .hero-content h1 {
		font-size: 1.5rem;
	}
	section.technology .hero .hero-content p,
	.technology .hero .hero-content p,
	section.security-section .hero .hero-content p,
	.security-section .hero .hero-content p {
		font-size: 0.9rem;
	}
	section.technology .hero .hero-content form input,
	.technology .hero .hero-content form input,
	section.security-section .hero .hero-content form input,
	.security-section .hero .hero-content form input {
		font-size: 0.9rem;
	}
	section.technology .hero .hero-content form button,
	.technology .hero .hero-content form button,
	section.security-section .hero .hero-content form button,
	.security-section .hero .hero-content form button {
		font-size: 0.9rem;
	}
}

section.security-section {
	padding: 50px;
	text-align: center;
	background-color: #fff;
}
section.security-section .btn {
	margin: 10px;
}
.smarter-way .container .row span.change {
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 10px;
	background-color: #30afee;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	color: #fff !important;
	font-family: Outfit;
	font-size: 26px !important;
	font-weight: 700 !important;
	letter-spacing: 3px;
	line-height: 22px;
	padding: 10px;
	margin: 20px 0;
	margin-right: 5px;
	max-width: -moz-max-content;
	max-width: max-content;
	display: flex;
}
section.technology h2,
.technology h2 {
	color: #000000;
	font-size: 1.88rem;
	padding: 20px 0 10px;
}
/* ######### */
/* adaptations - fixes done fpr speed */
/* ## */

html body {
	background-color: initial;
}

.request-part .hero {
	background: url("./../images/tech-bg-dark.webp") no-repeat center center;
	background-size: cover;
	padding: 20px 10px;
	text-align: center;
	color: #333333;
	min-height: 360px;
	height: 470px;
}

.hero-techBG {
	background: none;
	background-size: auto;
	padding: 30px 17px 10px 17px;
	color: #333333;
}
.hero-techBG .hero-content-techBG .buttons {
	justify-content: center;
	gap: 15px;
}

.hero-techBG .hero-content-techBG h1 {
	font-size: 2.5rem;
	margin-bottom: 20px;
	margin-top: 20px;
}

.hero-techBG .hero-content-techBG h1,
.security-question h1 {
	font-size: 2.66rem;
	margin-bottom: 20px;
	margin-top: 20px;
}

@media screen and (max-width: 768px) {
	.security-question .why,
	section.technology .why,
	.technology .why {
		text-align: center;
	}
	.security-question .why img,
	section.technology .why img,
	.technology .why img {
		max-width: 90%;
		margin: auto;
		width: 360px;
		padding-top: 20px;
	}
}
.security-question .how > .container > .row,
section.technology .how > .container > .row,
.technology .how > .container > .row {
	justify-content: center;
}
.security-question h2,
section.technology h2,
.technology h2 {
	color: #0f2733 !important;
	font-size: 1.65rem;
	padding: 15px 0 13px;
	font-weight: bold;
}

.hero-techBG .hero-content-techBG .buttons .demo-button,
.hero-techBG .hero-content-techBG .buttons .trial-button {
	padding: 10px 20px;
	font-size: 1rem;
	border: none;
	border-radius: 5px;
	background-color: #30afee;
	color: #ffffff;
	cursor: pointer;
}

.hero-techBG .hero-content-techBG .buttons .demo-button.second-type-button,
.hero-techBG .hero-content-techBG .buttons .trial-button.second-type-button {
	padding: 10px 20px;
	font-size: 1rem;
	border: none;
	border-radius: 5px;
	background-color: transparent;
	cursor: pointer;
	border: 2.5px solid #30afee;
	background: none;
	color: #30afee;
}

.technology
	.contact-us-section
	.contact-us-content
	.contact-us-right
	h2.contact-title {
	color: #ffffff !important;
}

section.technology .how div.icon svg,
.technology .how div.icon svg,
section.security-section .how div.icon svg,
.security-section .how div.icon svg {
	margin: 20px auto 10px auto;
	fill: #30afee !important;
}
section.technology .how div.icon svg path,
.technology .how div.icon svg path,
section.security-section .how div.icon svg path,
.security-section .how div.icon svg path {
	fill: #30afee !important;
}

section.technology .smarter-way,
.technology .smarter-way,
section.security-section .smarter-way,
.security-section .smarter-way {
	box-shadow: 0px 10px 3rem -8px rgba(0, 0, 0, 0.3);
	padding: 62px 0;
	border-bottom: 7px solid #0f2733;
	border-style: inset;
	margin-bottom: 30px;
}

.ff-page-intro-content h1 {
	font-size: 2.5rem;
	color: #000000;
}
.ff-page-intro-content .ff-page-intro-hero-content h1 {
	font-size: 2.5rem;
	color: #000000;
}

.ff-page-second-section .buttons {
	display: flex;
	justify-content: center;
	gap: 15px;
}
.ff-page-second-section .buttons .demo-button.second-type-button,
.ff-page-second-section .buttons .trial-button.second-type-button {
	padding: 10px 20px;
	font-size: 1rem;
	border: none;
	border-radius: 5px;
	background-color: transparent;
	cursor: pointer;
	border: 2.5px solid #30afee;
	background: none;
	color: #30afee;
}

.timeline {
	position: relative;
	padding: 20px;
}
.timeline .timeline-item,
.timeline .timeline-date,
.timeline .timeline-content,
.timeline .timeline-dot {
	display: inline-block;
}
.timeline .timeline-item {
	position: relative;
	position: relative;
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 30px 0;
}
.timeline .timeline-item .timeline-date {
	padding: 5px 10px;
	border-radius: 5px;
	font-weight: bold;
	font-size: 2rem;
	color: #30afee;
}
.timeline .timeline-item .timeline-content,
.timeline .timeline-item .timeline-date {
	width: 45%;
	position: relative;
}
.timeline .timeline-item .timeline-dot {
	/*width: 10%;*/
	width: auto;
	font-size: 2rem;
	font-weight: bold;
}
.timeline .timeline-item .timeline-dot::before,
.timeline .timeline-item .timeline-dot::after {
	content: " ";
	background: #0f2733;
	width: 2px;
	height: 30px;
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
}
.timeline .timeline-item .timeline-dot::before {
	top: -15px;
}
.timeline .timeline-item .timeline-dot::after {
	bottom: -15px;
}
@media screen and (max-width: 480px) {
	.timeline .timeline-item .timeline-dot {
		display: none;
	}
}

.margin-auto-use {
	margin: auto !important;
}

.ff-page-second-section .buttons.custom-layout-btns {
	justify-content: flex-start;
}

@media screen and (max-width: 768px) {
	.ff-page-second-section .buttons,
	.ff-page-second-section .buttons {
		justify-content: center;
	}
}
@media screen and (max-width: 468px) {
	.submit-button,
	.demo-button {
		width: auto !important;
	}
}
.round-box-lift.round-box-border-bottom {
	box-shadow: 0px 10px 3rem -8px rgba(0, 0, 0, 0.3) !important;
	padding: 62px 0 !important;
	border-bottom: 7px solid #0f2733 !important;
	border-style: inset !important;
	margin-bottom: 30px !important;
}

.contact-us-section.about-page-contact-us {
	position: relative;
	z-index: 1;
	padding-top: 70px;
	/* padding: 200px 20px 40px; */
	display: flex;
	justify-content: center;
	background-color: #ffffff;
	color: white;
	/* max-width: 1200px; */
	margin-left: auto;
	margin-right: auto;
}
.contact-us-section.about-page-contact-us .contact-us-right {
	flex: 1 1;
	background: none;
	padding: 0 !important;
	border-radius: 0;
	color: #000000;
	margin: 0;
	box-sizing: border-box;
	height: 100%;
}
.contact-us-section.about-page-contact-us .contact-us-right h2.contact-title,
.contact-us-section.about-page-contact-us .contact-us-right .contact-title {
	color: #0f2733 !important;
	font-size: 2.2rem;
}
.contact-us-section.about-page-contact-us .position-contact-form {
	display: flex;
	padding: 0px;
	flex-direction: column;
	align-items: normal;
	height: 100%;
	max-width: 95%;
	margin: auto;
}
.contact-us-section.about-page-contact-us
	.position-contact-form
	.contact-us-content {
	padding: 0;
	margin-top: auto;
	padding-bottom: 70px;
	margin: 0;
	padding: 0;
	height: 100%;
}
.contact-us-section.about-page-contact-us
	.position-contact-form
	.contact-us-content
	.contact-form
	.form-group {
	border: 0.55px solid;
}
.contact-us-section.about-page-contact-us .map-shot picture img {
	width: 90%;
	max-width: 480px;
	margin: auto;
}

section.technology .accordion .accordion-item .accordion-title .arrow,
.technology .accordion .accordion-item .accordion-title .arrow {
	transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
}

section.technology .accordion .accordion-item .accordion-title .arrow.active,
.technology .accordion .accordion-item .accordion-title .arrow.active {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
}

.technology
	.contact-us-section.about-page-contact-us
	.contact-us-right
	h2.contact-title,
.technology
	.contact-us-section.about-page-contact-us
	.contact-us-right
	.contact-title {
	color: #0f2733 !important;
	font-size: 2.2rem;
}

@media screen and (max-width: 768px) {
	.ff-page-second-section .buttons,
	.ff-page-second-section .buttons {
		justify-content: center !important;
	}
	section.technology
		.contact-us-section
		.contact-us-content
		.contact-us-right
		h2,
	section.technology
		.contact-us-section
		.contact-us-content
		.contact-us-right
		h2.contact-title,
	section.technology
		.contact-us-section
		.contact-us-content
		.contact-us-right
		.contact-title,
	.technology .contact-us-section .contact-us-content .contact-us-right h2,
	.technology
		.contact-us-section
		.contact-us-content
		.contact-us-right
		h2.contact-title,
	.technology
		.contact-us-section
		.contact-us-content
		.contact-us-right
		.contact-title {
		padding-top: 25px;
	}
}
@media (max-width: 991px) {
	.contact-us-section {
		padding-top: 80px;
	}
}

/* NEW NEW */
body h1 {
	font-size: 50px;
	color: #0f2733;
	margin-top: 10px;
	margin-bottom: 15px;
}
body h1:after {
	content: "";
	width: 70px;
	border: 2px solid rgb(47, 175, 237);
	display: block;
	margin-top: 10px;
}

body h2 {
	color: #30afee;
	font-size: 1.8rem;
	margin-bottom: 10px;
	text-transform: uppercase;
	font-weight: bold;
}

p.label-title-p,
p.hero-p {
	border-width: 0;
	border-style: solid;
	border-color: #e5e7eb;
	padding: 0;
	box-sizing: border-box;
	width: 100%;
	font-weight: 400;
	font-size: 20px;
	line-height: 1.8;
	color: #333;
	margin: 20px 0;
	max-width: -moz-max-content;
	max-width: max-content;
	display: inline-block;
}

body {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	color: #333333;
	background-color: #f5f5f5;
}
body h1:after {
	content: "";
	width: 70px;
	border: 2px solid rgb(47, 175, 237);
	display: block;
	margin-top: 10px;
}
body h1.center-text:after {
	content: "";
	margin: auto;
	margin-top: 10px;
}
body .logo {
	background-image: url("./../images/logo.png");
}
body .clearfix.section-breaks,
body .section-breaks {
	margin: 1px 0;
	padding: 27px 0px;
}

p.label-title-p {
	font-size: 26px;
	background-color: #30afee;
	background: #30afee;
	font-size: 26px !important;
	letter-spacing: 3px;
	line-height: 22px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	color: #ffffff !important;
	padding: 13px !important;
}

/* Extra Large (XL) Devices */
@media (min-width: 1200px) {
	.align-content-xl-center {
		align-content: center;
	}
	.align-content-xl-start {
		align-content: start;
	}
	.align-content-xl-end {
		align-content: end;
	}
	.margin-auto-xl {
		margin: 0 auto;
	}
}
/* Large (LG) Devices */
@media (max-width: 1199.98px) {
	.align-content-lg-center {
		align-content: center;
	}
	.align-content-lg-start {
		align-content: start;
	}
	.align-content-lg-end {
		align-content: end;
	}
	.margin-auto-lg {
		margin: 0 auto;
	}
}
/* Medium (MD) Devices */
@media (max-width: 991.98px) {
	.align-content-md-center {
		align-content: center;
	}
	.align-content-md-start {
		align-content: start;
	}
	.align-content-md-end {
		align-content: end;
	}
	.margin-auto-md {
		margin: 0 auto;
	}
}
/* Small (SM) Devices */
@media (max-width: 767.98px) {
	.align-content-sm-center {
		align-content: center;
	}
	.align-content-sm-start {
		align-content: start;
	}
	.align-content-sm-end {
		align-content: end;
	}
	.margin-auto-sm {
		margin: 0 auto;
	}
}
/* Extra Small (XS) Devices */
@media (max-width: 575.98px) {
	.align-content-xs-center {
		align-content: center;
	}
	.align-content-xs-start {
		align-content: start;
	}
	.align-content-xs-end {
		align-content: end;
	}
	.margin-auto-xs {
		margin: 0 auto;
	}
}
html body #root section.FrontPage .content h2,
html body #root .AboutPage .content h2,
html body #root .TechPage .content h2,
html body #root section.technology .content h2 {
	text-align: left;
	display: inline-block;
}
html body #root section.FrontPage .content .icon,
html body #root .AboutPage .content .icon,
html body #root .TechPage .content .icon,
html body #root section.technology .content .icon {
	display: inline-block;
	align-items: center;
	margin: 0 auto 0 0;
	text-align: left;
	max-width: -moz-max-content;
	max-width: max-content;
}
html body #root section.FrontPage .content .icon h2,
html body #root .AboutPage .content .icon h2,
html body #root .TechPage .content .icon h2,
html body #root section.technology .content .icon h2 {
	font-size: 1.45rem !important;
}
html body #root section.FrontPage .content .icon svg,
html body #root .AboutPage .content .icon svg,
html body #root .TechPage .content .icon svg,
html body #root section.technology .content .icon svg {
	margin: 20px auto 10px auto;
	fill: #30afee !important;
}

.about-content h1:after {
	content: none;
}

.why-choose-section h2:after {
	content: none;
}

.hero-techBG .hero-content-techBG .buttons {
	justify-content: flex-start;
	gap: 15px;
}

.hero-content-techBG h3,
.hero-content h3 {
	font-size: 44px;
	font-weight: 600;
	margin-top: 0px;
}
.hero-content-techBG p,
.hero-content p {
	font-weight: 400;
	font-size: 20px;
	color: rgb(51, 51, 51);
	margin: 20px 0;
}

.technology .how > .container > .row {
	justify-content: left !important;
}

section.technology h2,
.technology h2 {
	font-size: 1.55rem !important;
}

@media screen and (max-width: 576px) {
	.security-question .how > .container > .row,
	section.technology .how > .container > .row,
	.technology .how > .container > .row {
		justify-content: center !important;
	}
}

.request-part p {
	color: #ffffff !important;
}

.ff-page-second-section .buttons {
	justify-content: flex-start;
}

.demo-btn,
.hero-techBG .hero-content-techBG .buttons .demo-button {
	padding: 10px 20px;
	font-size: 12px;
	color: #ffffff;
	display: flex;
	text-align: center;
	width: -moz-fit-content;
	width: fit-content;
	cursor: pointer;
	border-radius: 4px !important;
	background-color: #30afee;
	align-items: center;
}

.hero-techBG .hero-content-techBG .buttons .demo-button.second-type-button {
	color: #30afee;
	font-size: 12px;
	display: flex;
	text-align: center;
	width: -moz-fit-content;
	width: fit-content;
}

section.technology .smarter-way a,
.technology .smarter-way a,
section.security-section .smarter-way a,
.security-section .smarter-way a,
a.demo-button,
button.submit-button {
	font-size: 12px;
	display: flex;
	text-align: center;
	width: -moz-fit-content;
	width: fit-content;
	padding: 10px 20px;
	align-items: center;
}

.timeline .timeline-item .timeline-content {
	text-align: justify;
}

.hero-techBG {
	padding: 30px 17px 10px 17px;
}

@media screen and (max-width: 768px) {
	.ff-page-second-section .buttons,
	.ff-page-second-section .buttons {
		justify-content: left !important;
	}
}

html body #root section.FrontPage .content h2,
html body #root .AboutPage .content h2,
html body #root .TechPage .content h2,
html body #root section.technology .content h2 {
	text-align: left;
	display: inline-block;
	min-height: 100px;
	/* line-height: 21px; */
	display: flex;
	align-items: baseline;
}

section.technology .how div.icon,
.technology .how div.icon,
section.security-section .how div.icon,
.security-section .how div.icon {
	min-height: 56px;
}

section.technology .how div.icon svg,
.technology .how div.icon svg,
section.security-section .how div.icon svg,
.security-section .how div.icon svg {
	margin: 20px auto 10px 0px;
}

@media (max-width: 1200px) {
	.request-part section.hero.d-flex .align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
}
.why-choose-section h1::after {
	content: none !important;
}
.text-red-500 {
	color: red !important;
	padding-top: 3px !important;
}
td.MuiTableCell-root svg[data-testid="DeleteIcon"] path {
	fill: #ae0202 !important;
}

section.ff-page-intro-content p,
.ff-page-intro-content p,
section.ff-page-second-section p,
.ff-page-second-section p,
section .why h1 ~ p,
.technology .how .content p,
.technology .how .content p,
section.technology .smarter-way p,
.technology .smarter-way p,
section.security-section .smarter-way p,
.security-section .smarter-way p,
section.technology .request-part .hero .hero-content p,
.technology .request-part .hero .hero-content p,
.why-choose-section.inner-why-choose p,
div.faq h1 + p {
	font-weight: 400;
	font-size: 20px;
	color: rgb(51, 51, 51);
	margin: 20px 0;
	font-size: 18.75px;
	line-height: 1.8;
}

.hero-content-techBG p,
.hero-content p {
	font-size: 20px;
}

.domain-scan-container + a.demo-button {
	display: inline-block !important;
	text-align: center !important;
	font-weight: bold !important;
	padding: 10px !important;
	font-size: 18px !important;
	border-radius: 4px !important;
	border: 1px solid #30afee !important;
	color: white !important;
	width: 182px !important;
	cursor: pointer !important;
	text-decoration: none;
	margin: -1px 0 0px 0px;
	background-color: #0f2733;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	transition: all 0.3s;
}
.contact-us-buttons button.submit-button,
.contact-us-buttons .submit-button {
	padding: 10px;
	font-size: 18px;
	border-radius: 4px !important;
	background-color: #30afee;
	border: 1px solid #30afee;
	color: white;
	width: 182px;
	cursor: pointer;
	text-align: center;
	display: unset;
}

.contact-us-buttons button.submit-button + a.demo-button,
.contact-us-buttons .submit-button + a.demo-button {
	margin: 0;
	background-color: #0f2733;
	border: 2px solid #30afee;
	/* padding: 10px; */
	font-size: 18px;
	border-radius: 4px !important;
	/* background-color: #30afee; */
	/* border: 1px solid #30afee; */
	color: white;
	width: 182px;
	cursor: pointer;
	display: block;
	text-align: center;
	width: fit-content;
	padding: 10px 20px;
	/* align-items: center; */
}

.hero-techBG .hero-content-techBG .buttons a.demo-button,
section.technology .buttons a.demo-button,
section .smarter-way a.demo-button,
section.technology .request-part .hero .hero-content a.demo-button {
	font-size: 12px;
	display: flex;
	text-align: center;
	width: fit-content;
	min-width: 165px;
	display: flex;
	margin-top: 20px;
	padding: 10px 30px;
	background-color: #30afee;
	color: #fff;
	text-decoration: none;
	border-radius: 5px;
	font-size: 18px;
	font-weight: bold;
	width: fit-content;
	align-items: center;
	text-align: center;
}
.hero-techBG .hero-content-techBG .buttons .demo-button.second-type-button,
section.technology .buttons .demo-button.second-type-button,
section .smarter-way .demo-button.second-type-button,
section.technology
	.request-part
	.hero
	.hero-content
	.demo-button.second-type-button {
	color: #30afee;
	font-size: 12px;
	display: flex;
	text-align: center;
	width: fit-content;
	min-width: 165px;
	display: flex;
	margin-top: 20px;
	padding: 10px 30px;
	background-color: #ffffff;
	text-decoration: none;
	border-radius: 5px;
	font-size: 18px;
	font-weight: bold;
	width: fit-content;
	align-items: center;
	text-align: center;
}
@media screen and (max-width: 768px) {
	.security-question .why,
	section.technology .why,
	.technology .why {
		text-align: left;
	}
}
section.ff-page-intro-content p.label-title-p,
.ff-page-intro-content p.label-title-p,
.hero-content-techBG p.label-title-p,
section.ff-page-second-section p.label-title-p,
.ff-page-second-section p.label-title-p {
	font-weight: 600;
}

.ff-page-intro-hero-content .timeline .timeline-item .timeline-content {
	font-size: 18.75px;
}

.ff-page-intro-hero-content .timeline .timeline-item .timeline-date {
	width: 25%;
	text-align: left;
}
.ff-page-intro-hero-content .timeline .timeline-item .timeline-content {
	width: 60%;
}

.ff-page-intro-hero-content .timeline .timeline-item .timeline-dot {
	/*width: 10%;*/
	position: relative;
}

@media screen and (max-width: 767px) {
	.ff-page-intro-hero-content .timeline .timeline-item {
		display: block;
	}
	.ff-page-intro-hero-content .timeline .timeline-item .timeline-date {
		width: unset;
		text-align: left;
		display: block;
		padding: 0;
	}
	.ff-page-intro-hero-content .timeline .timeline-item .timeline-content {
		width: unset;
	}
	.ff-page-intro-hero-content .timeline .timeline-item .timeline-dot {
		width: unset;
		position: relative;
		display: none;
	}
}

@media screen and (max-width: 480px) {
	.ff-page-intro-content .ff-page-intro-hero-content h1 {
		text-align: left !important;
	}
	.ff-page-intro-content .ff-page-intro-hero-content h1 ~ p.text-center {
		text-align: left !important;
	}
	.ff-page-intro-hero-content .timeline .timeline-item {
		display: block;
	}
	.ff-page-intro-hero-content .timeline .timeline-item .timeline-date {
		width: unset;
		text-align: left;
		display: block;
		padding: 0;
	}
	.ff-page-intro-hero-content .timeline .timeline-item .timeline-content {
		width: unset;
	}
	.ff-page-intro-hero-content .timeline .timeline-item .timeline-dot {
		width: unset;
		position: relative;
	}
}

/* Technology.css */
.technology-section-third {
	padding: 20px 0 35px;
	background-color: #f8f9fa;
}
.technology-section-third h1,
.technology-section-third h2,
.technology-section-third h3 {
	margin-bottom: 20px;
}

.technology-section-third p {
	font-weight: 400;
	color: rgb(51, 51, 51);
	margin: 20px 0;
	font-size: 18.75px;
	line-height: 1.8;
}
.technology-section-third .detection-methods,
.technology-section-third .investigation-methods,
.technology-section-third .monitoring-methods,
.technology-section-third .takedown-methods {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 20px;
}
.technology-section-third .method {
	border: 1px solid #ddd;
	padding: 20px;
	border-radius: 5px;
	background-color: white;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
}
.technology-section-third .method p.dynamic-p-handle {
	height: -moz-max-content;
	height: max-content;
	overflow: auto;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
}
.technology-section-third .method p.dynamic-p-handle.handle-close {
	height: 0px;
	overflow: hidden;
}
.technology-section-third .method-cards {
	display: flex;
}
.technology-section-third .method-card {
	border: 1px solid #ddd;
	padding: 20px;
	border-radius: 5px;
	background-color: white;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
	max-height: 100px;
	height: -moz-max-content;
	height: max-content;
	overflow: auto;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	height: 0px;
	overflow: hidden;
	min-height: 130px;
	max-height: 130px;
}
.technology-section-third .method-card.expanded {
	max-height: 500px;
	height: -moz-max-content;
	height: max-content;
	overflow: auto;
	min-height: 740px;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
}

.technology-section-third .method-card.card-header {
	padding: 0;
	/* margin-bottom: 0; */
	background-color: transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.technology-section-third .card-header {
	cursor: pointer;
}
.technology-section-third .card-header h3 {
	min-height: 55.5px;
	font-size: 1.55rem !important;
}
.technology-section-third .snippet {
	margin-bottom: 10px;
	color: #777;
}
.technology-section-third .card-content-tech-cards {
	padding-top: 10px;
}

.method-card.expanded .card-content-tech-cards {
	padding-top: 10px;
	height: auto;
}
.technology-section-third .see-more {
	background-color: transparent;
	border: none;
	color: blue;
	cursor: pointer;
	padding: 0;
	font-size: 10px;
	font-style: italic;
	font-weight: 500;
	color: #0f2733;
}
.technology-section-third .method-card .see-more.for-less {
	display: none;
}
.technology-section-third .method-card .see-more.for-more {
	display: block;
}

.technology-section-third .method-card.expanded .see-more.for-less {
	display: block;
	position: absolute;
	bottom: 40px;
}
.technology-section-third .method-card.expanded .see-more.for-more {
	display: none;
}

@media (max-width: 768px) {
	.detection-methods,
	.investigation-methods,
	.monitoring-methods,
	.takedown-methods {
		grid-template-columns: 1fr;
	}
}

.hero-techBG .hero-content-techBG .buttons .demo-button.second-type-button,
section.technology .buttons .demo-button.second-type-button,
section .smarter-way .demo-button.second-type-button,
section.technology
	.request-part
	.hero
	.hero-content
	.demo-button.second-type-button {
	justify-content: center;
}
.technology-third h2 {
	font-size: 44px;
	font-weight: 600;
	margin-top: 0px;
	text-transform: capitalize;
}

.technology-section-third .method-card .card-header {
	background-color: transparent;
	background: transparent;
}
.technology-section-third .method-card:hover {
	background: #30afee;
}

.technology-section-third .method-card:hover * {
	color: #fff !important;
}

.technology-section-third.second-of-third .icon-circle,
.monitoring-section.icon-circle {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.technology-section-third.second-of-third .feature-card,
.monitoring-section.feature-card {
	transition: transform 0.2s ease;
}

.technology-section-third.second-of-third .feature-card:hover,
.monitoring-section.feature-card:hover {
	transform: translateY(-5px);
}

.technology-section-third.second-of-third .monitoring-section,
.monitoring-section.monitoring-section {
	background-color: #f8f9fa;
}

.technology-section-third.second-of-third .customize-alerts,
.monitoring-section.customize-alerts {
	background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
}

.technology-section-third.second-of-third .card {
	z-index: 99;
}

.technology-section-third.second-of-third .card:hover {
	color: #fff;
	transform: scale(1.05);
	z-index: 999;
	background: #0f2733;
}

.technology-section-third.second-of-third .card:hover * {
	color: #fff !important;
	background: #0f2733 !important;
}

.monitoring-section .row.align-items-stretch {
	margin-bottom: 2rem !important;
}

.monitoring-section .customize-alerts {
	height: 100% !important;
	display: flex !important;
	flex-direction: column !important;
}

.monitoring-section .feature-card:last-child {
	margin-bottom: 0 !important;
}

.monitoring-section .monitoring-features {
	gap: 1rem !important;
}

@media (max-width: 991px) {
	.monitoring-section .customize-alerts {
		height: auto !important;
		margin-top: 2rem !important;
	}
}

.fas-custom:before {
	content: "\f129";
}

.inner-why-choose .steps-container .step .step-content .step-text h2 {
	color: #30afee !important;
	font-size: 1.8rem !important;
	margin-bottom: 10px !important;
}

body .why-choose-section.inner-why-choose h1:after {
	content: "" !important;
	width: 70px;
	border: 2px solid rgb(47, 175, 237);
	display: block;
	margin-top: 10px;
}

h1.class-h1-40 {
	font-size: 40px !important;
}

@media (max-width: 768px) {
	.why-choose-section.inner-why-choose .steps-container .step .step-content {
		flex-direction: column;
		text-align: center;
		border: 0.1px solid rgba(0, 0, 0, 0.1);
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
		padding: 25px 5px;
	}
}

@media screen and (max-width: 768px) {
	.hero-techBG .hero-content-techBG .buttons a.demo-button,
	section.technology .buttons a.demo-button,
	section .smarter-way a.demo-button,
	section.technology .request-part .hero .hero-content a.demo-button,
	.hero-techBG .hero-content-techBG .buttons .demo-button.second-type-button {
		min-width: 0px;
		display: flex;
		margin-top: 20px;
		padding: 10px 30px;
		text-decoration: none;
		border-radius: 5px;
		font-size: 15px;
		font-weight: bold;
		width: -moz-fit-content;
		width: auto;
		width: fit-content;
		align-items: center;
		text-align: center;
	}
	h1.class-h1-40,
	.technology-section-third h1,
	.hero-content-techBG h3 {
		font-size: 30px !important;
	}
	.technology-section-third {
		padding-top: 2px !important;
		padding-bottom: 2px !important;
	}
}
@media only screen and (max-width: 490px) {
	.learn-more {
		max-width: -moz-max-content;
		max-width: max-content;
		margin-right: 3px;
	}
	.learn-more-new {
		padding: 10px 10px !important;
		font-size: 10px !important;
		width: auto;
	}
}
