/* Importing Google Font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.0/css/all.min.css');



* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

/* General Styles */
body {
  font-family: 'Outfit', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  /* background: #eef4f7; */
  line-height: 1.6;
}

.navbar {
  z-index: 1 !important;
}

.navbar-nav .nav-item {
  position: relative;
  padding-right: 15px;
}

.navbar-nav .nav-item .book-demo {
  display: none;
}

.navbar-nav .nav-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -13px;
  top: 14px;
  height: 10px;
  border-right: 1px solid #525050;
}

::placeholder {
  color: #2973ab;
  opacity: 1;
  /* Firefox */
}

.contact-us-section .row {
  margin-right: 0px;
  margin-left: 0px
}

.form-row {
  margin-right: 0px;
  margin-left: 0px
}

.modal-benefits {
  display: flex;
  padding: 10px;
  align-items: flex-start;
}

.modal-benefits h3 {
  font-weight: 900;
  font-size: 20px;
}

.modal-benefits img {
  width: 80px
}


.contact-modal .form-group input,
.contact-modal .form-group textarea {
  border: 1px solid #2973ab;
}

.contact-image {

  display: none;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1240px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 800px !important;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.2rem !important;
  }
}

@media (max-width: 991px) {
  .contact-us-section {


    flex-direction: column;
  }

  .contact-image {

    display: block;
  }


}

@media (max-width: 992px) {


  .hero-text p {
    font-size: 16px;
  }

  @media (max-width:767px) {
    /* .hero-image{
    justify-content: center;
  } */
  }

  .testimonial-slider {
    padding: 10px 30px !important;
  }

  .navbar-expand-md {
    -ms-flex-flow: row nowrap !important;
    flex-flow: row nowrap !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-expand-md .navbar-nav .nav-link {

    font-size: 12px !important;
  }

  header nav ul {
    gap: 0px !important
  }

  .navbar-nav .nav-item:not(:last-child)::after {
    top: 10px;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-expand-md .navbar-nav .nav-link {
    font-size: 10px !important;
  }

  .navbar {
    padding: .5rem .1rem !important;
    margin-left: 0px !important;
  }

  header nav ul {
    gap: 0px !important;
  }

  .arrow-image {
    width: 9px !important;
  }

  .learn-more {
    display: inline-block !important;
    padding: 10px 20px !important;
    width: 110px !important;
    /* font-size: 9px!important; */
  }

}

@media only screen and (max-width: 490px) {
  .learn-more-new {
    padding: 10px 10px !important;
    font-size: 10px !important;
    width: 80px;
  }

  .iconstyle {
    padding-left: 8px !important;
    font-size: 13px;
    top: 26px !important;
  }

  footer .footer-content {
    gap: 40px !important;
  }

  .about-section {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 490px) and (max-width: 768px) {
  .iconstyle {
    top: 38px !important;
    padding-left: 20px !important;
  }

  .about-image {
    text-align: center !important;
  }

  .about-content {
    text-align: center !important;
  }

  .horizontalstyle,
  .about-content .learn-more-btn {
    margin: 20px auto !important;
  }

  .about-section {
    padding: 0 !important;
  }

  footer .footer-content {
    gap: 40px !important;
  }
}

.iconstyle {
  position: absolute;
  top: 40px;
  padding-left: 15px;
}

header {
  /* display: flex;
  justify-content: space-evenly; */
  align-items: center;
  /* background-color: #003366; */
  padding: 10px;
  color: #fff;
  background: #0f2733;
}

header .logo h1 {
  font-weight: 600;
  font-size: 24px;
  margin-left: 10px;
}

header .logo img {

  max-width: 200px;

}

header nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

header nav ul li {
  margin: 0;
}

header nav ul li a {
  color: #fff !important;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
}

header .cta-button {
  background-color: #2973ab;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
}

.hero-section {
  display: flex;
  align-items: center;
  /* background: url('ecro/background-image.png') no-repeat center center/cover; Replace with your hero background */
  padding: 50px 0px;
}

.hero-text h2 {
  font-size: 40px;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
}

.hero-text h3 {
  font-size: 44px;
  font-weight: 600;
  margin-top: 0px;
}


.hero-text span {
  color: #fff;
}

.hero-text p {
  font-weight: 400;
  font-size: 20px;
  color: rgb(51, 51, 51);
  margin: 20px 0;
}

.hero-image img {
  width: 100%;
  max-width: 600px;
}

.hero-section .cta-button {
  background-color: #2973ab;
}

.organized-records-section {
  padding: 50px;
  background-color: #f5f5f5;
}

.organized-records-section h3 {
  font-size: 16px;
  color: #e57438;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
}

.organized-records-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Services Section */
.services-section {
  padding: 50px;
  background-color: #fff;
  /* Replace with your background image */
  background-size: cover;
  background-position: center;
}

.services-heading {
  text-align: center;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 30px;
}

.services-heading span {
  color: #2973ab;
  /* Adjust the color as needed */
}

.services-container {
  display: flex;
  /* gap: 20px; */
  align-items: center;
  /* Align items in the center */
}

.services-visual {
  position: relative;
  max-width: 600px;
  flex-shrink: 0;
}

.services-visual .doctor-image {
  width: 90%;
  height: auto;
  display: block;
  margin-top: 0px;
}

.connection-image {
  position: absolute;
  top: 52%;
  left: 90%;
  transform: translate(-50%, -50%);
  max-width: 260px;
  z-index: 2;
  /* Ensure it's on top */
}

.services-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
}

.service.capsule {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 100px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  width: 500px;
}

.service.capsule img {
  width: 80px;
  /* Adjust the size as needed */
  height: auto;
  flex-shrink: 0;
}

.service.capsule h3 {
  font-weight: 600;
  margin-top: 0;
  font-size: 18px;
}

.service.capsule p {
  font-weight: 400;
  margin: 5px 0 0;
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .services-visual {
    max-width: 100%;
  }

  .connection-image {
    max-width: 150px;
  }

  .services-content .service {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .service.capsule img {
    margin-bottom: 10px;
  }

  .service.capsule {
    width: 100%;
    padding: 15px;
    border-radius: 30px;
  }

  .services-visual .doctor-image {
    margin-top: 20px
  }

  .cases-section {
    padding: 250px 0px 200px 0px !important;
  }
}

/* Cases Section */
.cases-section {
  padding-left: 100px;
  padding-top: 50px;
  background-color: #f8f9fa;
  /* Background image with hexagon design */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;

}

.cases-section h2 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 30px;
  position: relative;
}

.cases-section h2 span {
  color: #2973ab;
  /* Adjust the color as needed */
}

.cases-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.cases-content {
  flex-basis: 50%;
}

.case-item {
  margin-bottom: 20px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 15px;
}

.case-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  padding: 15px 0;
}

.case-title .arrow1 {
  font-size: 1.5em;
  transition: transform 0.3s;
}

.case-title.active .arrow1 {
  transform: rotate(180deg);
}

.case-description {
  display: none;
  padding: 10px 0;
  font-size: 1em;
  color: #555;
}

.case-description p {
  margin-bottom: 15px;
}

.technology a {
  margin-bottom: 4rem;
}

.technology h2 {
  color: #30afee !important;
  margin-bottom: 0;
}

.technology p.small {
  margin-bottom: 2rem !important;
}


p.small {
  font-size: 15px !important;
}

.learn-more {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  width: 110px;
  font-size: 12px;
  border-radius: 5px;
  background-color: #229fdd;
  color: #ffffff;
}

.learn-more-new {
  display: inline-block;
  padding: 10px 20px;

  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.cases-image {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cases-image img {
  width: 100%;
  height: auto;
  /* max-width: 400px; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .cases-container {
    flex-direction: column;
    align-items: center;
  }

  .cases-content,
  .cases-image {
    flex-basis: 100%;
    max-width: 100%;
  }

  .cases-image img {
    max-width: 100%;
  }
}



/* Why AcroDocz Section */
.why-acrodocz-section {
  padding: 60px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.why-acrodocz-section h2 {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 40px;
}

.why-acrodocz-section h2 span {
  color: #2973ab;
  /* Highlight color for "AcroDocz" */
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 150px);
  justify-items: center;
  align-items: center;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

.feature-item {
  background-color: #fff;
  padding: 20px;
  width: 220px;
  height: 220px;
  transform: rotate(45deg);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.feature-item img {
  width: 100%;
  max-width: 70px;
  height: auto;
  transform: rotate(-45deg);
  border-radius: 10px;
  margin-left: -50px;
  margin-top: -65px;
}

.feature-item .content {
  position: absolute;
  text-align: center;
  transform: rotate(-45deg);
  width: 100%;
  margin-top: 50px;
  margin-left: 50px;
  padding: 25px;
}

.feature-item h3 {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 0.9em;
  color: #666;
}

/* Positioning the diamonds in a "W" shape */
.feature-item.feature-1 {

  grid-column: 2 / 3;
  grid-row: 1 / 4;
}

.feature-item.feature-2 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.feature-item.feature-3 {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
}

.feature-item.feature-4 {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.feature-item.feature-5 {
  grid-column: 2 / 4;
  grid-row: 3 / 4;
}

.learn-more-button {
  margin-top: 10px;
}

.learn-more-button .learn-more {
  display: inline-block;
  background-color: #2973ab;
  color: #fff;
  padding: 12px 30px;
  width: 183px;
  /* border-radius: 50px; */
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s;
}

.hero-text .learn-more {
  bacgkround-color: #2973ab;

}

.learn-more-button .learn-more:hover {
  background-color: #2973ab;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .features-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 150px);
    gap: 150px;
  }

  .feature-item {
    width: 200px;
    height: 200px;
  }

  /* Adjust positions for responsive */
  .feature-item.feature-1 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .feature-item.feature-2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .feature-item.feature-3 {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .feature-item.feature-4 {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .feature-item.feature-5 {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  .learn-more-button {
    margin-top: 0px !important;
  }
}

@media (max-width: 991px) {
  .services-visual .doctor-image {
    width: 100%;
  }


  .navbar-nav .nav-item:not(:last-child)::after {
    top: 10px;
    right: 5px;
  }

  .navbar-toggler {
    background: #fff;
  }

  .navbar {
    margin-left: 20px;
  }

  .connection-image {
    display: none
  }

  .features-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 150px);
  }

  .feature-item {
    width: 125px;
    height: 125px;
  }

  /* Adjust positions for small screens */
  .feature-item.feature-1,
  .feature-item.feature-2,
  .feature-item.feature-3,
  .feature-item.feature-4,
  .feature-item.feature-5 {
    position: relative;
    grid-column: 1 / 2;
    grid-row: auto;
    margin: 0 auto;
    width: 200px;
    height: 200px;
    margin-bottom: 120px;
    margin-top: 200px;
  }

  .learn-more-button {
    margin-top: 50px !important;
    margin-bottom: 50px;
  }

  .feature-item h3 {
    font-size: 0.8em;
  }

  .feature-item img {
    /* max-width: 40px; */
    margin-top: -50px;
  }

  .eclipse-image1 {
    margin-left: -80px !important
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .features-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;
    max-width: 700px;
    /* Adjust the max-width to better fit the design */
    margin: 0 auto;
  }

  .feature-item {
    width: 200px;
    height: 200px;
  }

  .feature-item.feature-1 {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .feature-item.feature-2 {
    grid-column: 1;
    grid-row: 2;
  }

  .feature-item.feature-3 {
    grid-column: 2;
    grid-row: 2;
  }

  .feature-item.feature-4 {
    grid-column: 1;
    grid-row: 3;
  }

  .feature-item.feature-5 {
    grid-column: 2;
    grid-row: 3;
  }

  .learn-more-button {
    grid-column: 1 / 3;
    margin-top: 20px;
  }
}




.blogs-section {
  background-color: #f0f8fa;
  padding: 100px 20px;

}

.blogs-section h2 span {
  color: #0c548e;
}

.section-title {
  font-size: 36px;
  color: #2c3e50;
  margin-bottom: 30px;
}

.blog-layout {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: stretch;
  margin-bottom: 100px;

}

.large-blog {
  flex: 1;
  position: relative;
  /* height: 100%; */
  overflow: hidden;
  /* border-radius: 8px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.large-blog-background {
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.large-blog-content {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 20px;
  width: 100%;
}

.large-blog-date {
  font-size: 10px;
  background-color: #2973ab;
  padding: 5px 10px;
  /* border-radius: 4px; */
  display: inline-block;
  margin-bottom: 10px;
  width: 120px;
  /* display: flex; */
  align-items: center;
}

.large-blog-date .clock-icon {
  /* margin-right: 8px; */
  vertical-align: middle;
  width: 10px;
  /* Add your clock icon style or use a font icon library */
}

.large-blog-title {
  font-size: 24px;
  margin: 0;
}

.large-blog:hover {
  transform: translateY(-10px);
}

.small-blogs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.small-blog {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* border-radius: 8px; */
  overflow: hidden;
  transition: transform 0.3s ease;
  height: 100%;
}

.small-blog:hover {
  transform: translateY(-10px);
}

.small-blog-image {
  width: 50%;
  object-fit: cover;
  height: auto;
}

.small-blog-content {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.small-blog-date {
  font-size: 10px;
  background-color: #2973ab;
  padding: 5px 10px;
  /* border-radius: 4px; */
  display: inline-block;
  margin-bottom: 10px;
  /* display: flex; */
  color: #ffffff;
  align-items: center;
  width: 102px;
}

.small-blog-date .clock-icon {
  width: 10px;
  /* margin-right: 8px; */
  vertical-align: middle;
  /* Add your clock icon style or use a font icon library */
}

.small-blog-title {
  font-size: 18px;
  color: #2c3e50;
  margin: 0;
}

.small-blog p {
  font-size: 12px;
  color: #7c7c7c;
}

@media (max-width: 991px) {
  .blog-layout {
    flex-direction: column;
  }

  .large-blog,
  .small-blog {
    flex: none;
    width: 100%;
  }

  .small-blog {
    flex-direction: row;
  }

  header .logo img {
    max-width: 120px;
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.eclipse-image1 {
  width: 120px;
  margin-left: -134px;
  /* Adjust size as needed */
  margin-right: 15px;
  /* Spacing between image and title */
}

.section-title {
  font-size: 36px;
  color: #2c3e50;
  margin: 0;
  /* Remove default margin */
}




.navbar-toggler {
  padding: .1rem .3rem;
}

footer {
  padding: 40px 0px 0 0px;
  background-color: #0f2733;
  color: #fff;
}

footer .footer-content {
  display: flex;
  gap: 20px;
  padding-bottom: 0;
}

footer .footer-content .footer-links ul {
  list-style: none;
  padding-left: 20px;
}

footer .footer-content .footer-links ul li {
  margin: 10px 0;
}

footer .footer-content .footer-links ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
}

footer .footer-content .footer-contact p {
  margin: 5px 0;
}

footer .footer-logo {
  flex: 1;
}

footer .footer-logo p {
  margin-top: 15px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
}

.footer-content .footer-links,
.footer-content .footer-contact {
  flex: 1;
}

/* Responsive Design */
@media (max-width: 991px) {
  .hero-section {
    flex-direction: column;
    padding: 10px 20px;
  }

  .hero-text h2 {
    font-size: 30px;
  }

  .hero-text h3 {
    font-size: 30px;
    font-weight: 400;
    margin-top: 0px;
  }

  .organized-records-content,
  .services-content,
  .cases-content,
  .contact-content {
    flex-direction: column;
  }

  .features {
    flex-direction: column;
  }

  footer .footer-content {
    flex-direction: column;
  }
}

.contact-us-section {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  /* padding: 200px 20px 40px; */
  display: flex;
  justify-content: center;
  background-color: #30afee;
  color: white;
  /* max-width: 1200px; */
  margin-left: auto;
  margin-right: auto;
  /* border-radius: 8px; */
}

.contact-us-background {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -2px;

  right: 0;
  bottom: 0;
  background-image: url('../public/assets/images/online/Group\ 151.png');
  background-size: cover;
  background-position: center;
  /* border-radius: 8px; */
  z-index: -1;
  /* Place background behind content */
}

.contact-logo {
  position: absolute;
  top: 16%;
  left: 15%;
  transform: translateX(-50%);
  z-index: 2;
  width: 550px;
  height: auto;
}

.contact-us-content {
  /* position: relative;
  width: 100%;
  max-width: 1000px;
  display: flex;
  gap: 40px;
  align-items: flex-start; */
  padding: 100px 40px 30px 40px;
  margin-top: 200px;
}

.contact-us-left {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.2);
  margin-right: 15px;
  padding: 30px;
  border-radius: 0px 40px;
}

.contact-us-left p {
  font-size: 20px;
}

.contact-title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #ffffff;
}

.contact-us-right {
  flex: 1;
  background: #0f2733;
  padding: 30px 40px !important;
  border-radius: 0px 40px;
  color: #ffffff;

  margin: 0;
  box-sizing: border-box;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap !important;
}

.form-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
}

.form-group label {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ffffff;
}

.form-group input,
.form-group textarea {
  padding: 15px 35px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  /* Slightly transparent input background */
  color: #2973ab;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.9);
}

textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.submit-button,
.demo-button {
  padding: 10px;
  font-size: 18px;
  border-radius: 4px !important;
  background-color: #30afee;
  border: 1px solid #30afee;
  color: white;
  width: 182px;
  cursor: pointer;
}

.demo-button {
  margin-top: 2rem;
  display: block;
  text-align: center;
}

.learn-button {
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid #2973ab;
  border-radius: 0px;
  background-color: transparent;
  color: #2c3e50;
  width: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


/* Adjust for responsiveness */
@media (max-width: 991px) {

  .contact-us-content {
    flex-direction: column;
    padding: 20px;
  }

  .form-row {
    flex-direction: column;
  }

  .contact-us-left,
  .contact-us-right {
    width: 100%;
  }

  .contact-logo {
    width: 80px;
    /* Adjust logo size for smaller screens */
  }

  .independent-dropdown .learn-more {
    display: none
  }
}

/* Fixed Width Dropdown Menu */
.independent-dropdown {
  position: absolute;
  top: 53px;
  transform: translateX(-37%);
  background-color: #229fdd;
  z-index: 1000;
  border: 1px solid #ddd;
  padding: 10px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.independent-dropdown.hide {
  display: block;
}

.independent-dropdown .nav-item {
  text-align: center;
  padding: 0;
}

.independent-dropdown .nav-link {
  color: #333;
  /* Text color */
  font-weight: 600;
  /* Optional: Bold text */
}

.hero-buttons {
  position: relative;
}

.hero-buttons a {
  position: absolute;
  top: 0;
  right: -200px;

}


@media (min-width: 768px) {


  .independent-dropdown {
    position: relative;
    /* Revert to normal behavior on larger screens */
    top: 0;
    left: auto;
    transform: none;
    background-color: transparent;
    border: none;
    padding: 0;
    width: auto;
    /* Allow full width on larger screens */
    box-shadow: none;
  }
}

.cases-section {
  padding: 200px 0px;
  position: relative;
  height: 1000px;
  padding-right: 60px;
  /* Add padding to the right to accommodate the image */
}

.cases-image {
  position: absolute;
  top: 0;
  right: 0;
  /* Aligns the image to the right edge of the section */
  width: 50%;
  /* Adjust the width of the image */
}

@media (max-width: 767.98px) {
  .cases-section {
    padding-right: 15px;
    /* Reduce padding for smaller screens */
  }

  .cases-image {
    position: static;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}

.footer-logo,
.footer-links .footer-contact {
  position: relative;
  /* border-right: 1px solid #0c548e; */
  padding: 20px;
  /* Optional padding */
}



.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the content horizontally */

  padding: 10px 20px 20px;
  position: relative;
}

.footer-bottom p {
  margin: 0;
  /* Remove any default margin */
  flex: 1;
  /* Allows the text to be centered while pushing the image to the right */
  text-align: center;
  /* Center the text */
}

.footer-bottom img {
  position: absolute;
  right: 20px;
  /* Adjust this value for spacing from the right */
}

.scan-progress {
  * {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px !important;
  }
}

.scan-progress-results>div>div {
  justify-content: space-between;
}


.scan-progress-results>div>div span {
  max-width: 180px;
  height: 6px;
}

@media (max-width: 991px) {

  .buttons-container .hero-buttons a.demo-button {
    position: relative;
    width: 100%;
    right: 0;
    margin-top: 1rem;
  }

  footer {
    padding-top: 30px;
  }

  footer .footer-content {
    padding-bottom: 0;
  }

  .scan-progress {
    padding: 30px !important;
  }

  .scan-progress-steps {
    display: none !important;
  }

  .scan-progress-loader {
    justify-content: center;
  }

  .scan-progress-steps div {
    padding: 0 4px;
  }

  .scan-progress-steps div span {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .scan-progress-steps div span span {
    padding: 0;
  }

  .scan-progress-results {
    flex-direction: column;
  }

  .scan-progress-results>div>div {
    gap: 0
  }

  .scan-progress-results div div p,
  .scan-progress-results div div span {
    min-width: 50%
  }

  .preview-scan-table tr td:first-child,
  .preview-scan-table tr td:nth-child(3),
  .preview-scan-table tr td:nth-child(4) {
    word-break: break-all;
  }

  .preview-scan-details {
    min-height: auto;
  }

  .preview-scan-details>div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .preview-scan-details>div>div {
    width: 100%;
    min-height: auto;
    border: none;
  }

  .preview-scan-details>div>div>div {
    width: 100%;
    min-height: auto;
  }

  .about-image {
    display: flex;
    justify-content: center;
  }

  .demo-section {
    flex-direction: column;
  }

  .democard {
    margin-top: 30px;
    width: 100% !important;
  }

  .newscard-container {
    flex-direction: column;
  }

  .newscard {
    width: 100% !important
  }

  .text-content {
    padding: 20px !important;
  }

  .arrow {
    margin-top: -8px !important;
  }

  .arrown {
    margin-top: -211px !important;
  }

  .casescont {
    margin-top: 400px;
  }

  .footer-logo,
  .footer-links {
    border: none !important;
  }

  .footer-logo::before,
  .footer-links::before {

    background-color: #fff;
    /* Color of the rectangle */
  }


  .blogs-section {
    /* margin-top:250px; */

  }

  .contact-us-background {
    background-position: right;
    background-repeat: no-repeat;
  }

  .contact-logo {
    display: none;
  }
}

@media (max-width: 540px) {
  .arrow {
    margin-top: -8px !important;
  }

  .arrown {
    margin-top: -261px !important;
  }

  .casescont {
    margin-top: 200px;
  }

  .large-blog-background {
    height: 500px;
  }

  .small-blog-image {
    width: 100%;
  }

  .small-blog-content {
    width: 100%;
  }

  .small-blog {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .services-content {
    flex-direction: column;
  }

  .services-content .service {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .services-container {
    flex-direction: column;
    align-items: center;
  }
}


.organized-records-section {
  padding: 60px 0;
  overflow-x: hidden;
  /* Prevent horizontal overflow */
}

.content-wrapper {
  display: flex;
  align-items: flex-start;
  /* Ensure alignment at the top */
  gap: 20px;
}

.services-image {
  width: 100%;
  /* Adjust as needed */

  flex-shrink: 0;
  /* Prevent shrinking */
}

.text-content {

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 50px;
  /* Take up remaining space */
  /* Position for the eclipse image */
}

.eclipse-image {
  position: absolute;
  right: -50px;
  /* Position to the right side of the text */
  top: 110px;
  /* Adjust as needed for vertical alignment */
  width: 150px;
  /* Adjust the size */
}

.learn-more-button {
  margin-top: 20px;
  /* Spacing from the text */
}

@media (max-width: 991px) {
  .about-image {
    margin-top: 30px;
  }

  .about-image img,
  .step-img {
    max-width: 200px !important;
    width: 200px !important;
    height: 200px !important;
  }

  .step-text {
    padding: 15px !important;
  }

  .step p {
    margin: 0;
  }

  .about-content {
    padding: 40px 10px;
  }

  .step {
    margin: 0 !important;
  }

  .steps-container {
    padding-top: 0 !important;
  }

  .about-content h1 {
    font-size: 30px !important;
  }

  .about-content .learn-more-btn {
    width: 160px !important;
    font-size: 12px !important;
  }

  .why-choose-section h1 {
    font-size: 30px !important
  }

  .card1 {
    margin-top: 20px !important;
  }

  .card2 {
    margin-top: 20px !important;
  }

  .card3 {
    margin-top: 20px !important
  }

  .card4 {
    margin-top: 20px !important
  }

  .card5 {
    margin-top: 20px !important;
  }

  .card6 {
    margin-top: 20px !important
  }

  .buttons-container a {
    /* padding: 10px 5px !important;
    font-size: 12px !important; */
  }

  .buttons-container {
    display: flex;
    justify-content: center;
  }

  .card-content h2 {
    font-size: 30px
  }

  .blue-btn {
    font-size: 12px !important
  }

  .orange-btn {
    font-size: 12px !important
  }

  .testimonial-title {
    font-size: 30px !important
  }

  .testimonial-container {
    width: 100% !important
  }

  .news-section h2 {
    font-size: 30px !important
  }

  .newscard-content a {
    font-size: 12px !important
  }

  .customer-title {
    font-size: 30px !important
  }

  .slick-dots li button {
    width: 20px !important;
  }

  .contact-title {
    font-size: 30px !important
  }

  footer {
    padding: 30px
  }

  .footer-logo,
  .footer-links {
    padding: 0px !important
  }

  .organized-records-section h2 {
    font-size: 28px !important
  }

  .content-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* Center text on small screens */
  }

  .services-image {
    max-width: 100%;
    /* Adjust width for smaller screens */
  }

  .eclipse-image {
    /* position: static; */
    margin-top: 10px;
    top: 80px;
    width: 70px;
    /* Adjust size for smaller screens */
  }

  .why-acrodocz-section .container {
    padding-top: 50px
  }

  .why-acrodocz-section {
    margin-top: 0px;
  }

  .newstyle {
    margin-bottom: -25px;
  }

  .centered-container1 {
    margin-left: 0px !important;
    margin-top: 100px !important;

  }

  .arrow {
    display: none;
  }

  .learn-more-button {
    align-items: center !important;
  }

  .contact-us-section {
    padding-top: 0
  }
}

@media (max-width: 540px) {
  .centered-container1 {
    margin-top: 280px !important;

  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -111px;
}

.centered-image {
  max-width: 100%;
  /* Optional: limit the size */
  height: auto;
  /* Maintain aspect ratio */
}

.centered-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 490px;
  margin-left: 65px;

}


.newbg {
  background: url('../public/assets/images/SG_landing_woman3.webp') no-repeat center center/cover;
}

.techBg {
  background: url('../public/assets/images/SG_man_woman_desk_hero2.webp') no-repeat center center/cover;
}


.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Space between each contact item */
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-item img {
  margin-right: 10px;
  /* Space between image and text */
}

.contact-item p {
  margin: 0;
  /* Remove default margin */
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.form-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: -10px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0px;
  box-sizing: border-box;
}

.form-group input,
.form-group textarea {
  padding: 15px 40px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  /* Make sure padding and borders are included in the width */
}

/* Placeholder styling */
.input-icon::placeholder {
  color: #292929;
  /* Custom placeholder color */
  opacity: 1;
  /* Ensure full visibility */
}

/* Placeholder styling */
input::placeholder {
  color: #292929 !important;
  /* Custom placeholder color */
  opacity: 1;
  /* Ensure full visibility */
}

/* Full-width on mobile */
@media (max-width: 991px) {
  .form-row {
    flex-direction: column;
  }

  .form-group input,
  .form-group textarea,
  .submit-button {
    width: 100%;
    margin: 0;
    /* Ensure no margin is added */
  }
}

.online-text {
  font-size: 26px !important;
  letter-spacing: 3px;
  line-height: 22px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  font-weight: 700 !important;
  font-family: "Outfit";
  background-color: #30afee;
  padding: 10px;
  margin-right: 5px !important;
}

.icon-circle {
  background-color: #2973ab;
  /* Circle background color */
  border-radius: 50%;
  /* Make the background a circle */
  padding: 10px;
  /* Adjust the size of the circle */
  display: inline-block;
  /* Ensure the icon and background are aligned */
  color: #fff;
  /* Icon color */
  font-size: 18px;
  width: 40px;
  height: 40px;
  /* Icon size */
  text-align: center;
  /* Center the icon within the circle */
  margin-right: 10px;
}

.icon-circlem {
  background-color: #fff;
  border-radius: 50%;
  padding: 14px;
  width: 50px;
  height: 50px;
  display: inline-block;
  color: #0f2733;
  font-size: 23px;
  text-align: center;
  margin-right: 15px;
}

.section-padding {
  padding: 50px 0px 50px 0px;
}

.news-section {
  /* text-align: center; */
  margin-bottom: 20px;

  padding: 100px 0;
}

.news-section h2 {
  color: #2b72b5;
  font-size: 40px;
}

.newscard-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;
}

.newscard {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 30%;
}

.newscard img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.newscard-content {
  padding: 20px;
  padding-bottom: 50px;
}

.newscard-content h3 {
  font-size: 1.4em;
  color: #333;
  margin-bottom: 10px;
}

.newscard-content p {
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

.newscard-content .date {
  background-color: #2973ab;
  color: white;
  font-size: 0.8em;
  padding: 3px 10px;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: -50px !important;
  position: relative;
  top: -33px;
}

.newscard-content .date i {
  margin-right: 5px;
}

.newscard-content a {
  text-decoration: none;
  color: white;
  background-color: #e57438;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: bold;
}

.newscard-content a:hover {
  background-color: #e57438;
}


.about-section {
  /* display: flex; */
  align-items: center;
  /* justify-content: center; */
  /* padding: 50px;
  background-color: #fff; */
  background-image: url('../public/assets/images/online/about-background.png');
  padding: 50px 0px 50px 0px;
  background-size: cover;
}

.about-content {

  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.about-content h2 {
  font-size: 16px;
  color: #e57438;
  text-transform: uppercase;
  font-weight: bold;
}

.about-content h1 {
  font-size: 50px;
  color: #0f2733;
  margin-top: 10px;
  margin-bottom: 0px;
}

.about-content p {
  font-size: 18px;
  line-height: 1.8;
  color: #333;
}

.about-content .learn-more-btn {
  width: 165px;
  display: inline-block;
  margin-top: 20px;
  padding: 10px 30px;
  background-color: #30afee;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
}



.arrow-image {
  margin-left: 10px;
  width: 12px;
}

.about-content .learn-more-btn:hover {
  background-color: #2973ab;
  color: #fff;
}

.about-image {
  display: flex;
  justify-content: center;
}

.about-image img {

  max-width: 350px;
  width: 350px;
  height: 350px;
  object-fit: cover;
}

/* .about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #FF7B3D;
  z-index: -1;
  clip-path: polygon(0 0, 70% 0, 30% 100%, 0% 100%);
} */

.why-choose-section {
  padding: 50px 20px;
  background: #fff;

}

.why-choose-section h2 {
  font-size: 16px;
  color: #30afee;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
}

.why-choose-section h1 {
  font-size: 40px;
  color: #0f2733;

}


.why-us-image {
  width: 100px
}

.card1 {
  margin-top: 50px;
}

.card3 {
  margin-top: -50px
}

.card4 {
  margin-top: 50px
}

.card6 {
  margin-top: -50px
}

.card1:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card2:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card3:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card4:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card5:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card6:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card7:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card8:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card9:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card10:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card11:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.card12:hover .why-us-image {

  /* Change this to the new image path */
  transition: 0.1s ease;
  /* Optional: smooth transition effect */
}

.contact-modal .card1,
.card2,
.card3,
.card4,
.card5,
.card6,
.card7,
.card8,
.card9,
.card10,
.card11,
.card12 {
  margin-top: 30px
}

.modal-benefits:hover {
  background-color: #2973ab;
  padding: 10px;
  color: #fff;
  transform: scale(1.05);
  /* Slightly enlarge card on hover */
}




.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /* Responsive grid */
  gap: 40px;
  /* Space between cards */
  justify-items: center;
  /* Center items in each grid cell */
  margin: 0 auto;
  /* Center the grid */
  max-width: 1200px;
  /* Optional: Limit the maximum width of the grid */
}

.card {
  background-color: #fff;
  border-radius: 0px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* Full width of the grid item */
  padding: 30px;
  min-height: 300px;
  text-align: left;
  position: relative;
  /* Relative positioning for icon */
  transition: transform 0.2s;
  /* Add hover effect */
}

.card:hover {
  background-color: #2973ab;
  color: #fff;
  transform: scale(1.05);
  /* Slightly enlarge card on hover */
}

.card:hover p {
  color: #fff;
}

.card.blue {
  background-color: #2973ab;
  color: #fff;
}

.card h3 {
  font-weight: 600;
  font-size: 20px;
  margin: 15px 0 10px;
  /* Adjusted margin */
}

.card p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.card.blue p {
  color: #fff;
}

.card i {
  font-size: 32px;
  margin-bottom: 15px;
  color: #e57438;
  /* Icon color */
}

.buttons-container {
  text-align: center;
  margin-top: 40px;
}

.buttons-container a {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 25px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  margin: -1px 0 0px 0px;
  border: 2px solid #30afee;
  background-color: #0f2733;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  /* Smooth transition */
}

.see-benefits {
  width: 200px;
  background-color: #e57438;
  color: #fff;
}

.see-benefits:hover {
  background-color: transparent;
  color: #e57438;
  border: 2px solid #e57438;
}

.free-demo {
  width: 200px;
  background-color: #2973ab;
  color: #fff;
}

.free-demo:hover {
  background-color: transparent;
  color: #2973ab;
  border: 2px solid #2973ab;
}



.demo-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 50px;
}

.democard {
  width: 48%;
  position: relative;
  border-radius: 8px;

  text-align: center;
  overflow: hidden;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 50px;
  right: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: 0;
  margin-bottom: 100px;
}


.democard-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}


.demobutton {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.blue-btn {
  background-color: #2973ab;
  color: white;
  border-radius: 50px;
  border: 1px solid;
  width: fit-content;
  padding: 10px 20px;
}

.orange-btn {
  background-color: #e57438;
  color: white;
  border-radius: 50px;
  border: 1px solid;
  width: fit-content;
  padding: 10px 20px;
}

.blue-card .card-content {
  background-color: #2973ab;
}

.orange-card .card-content {
  background-color: #e57438;
}

.testimonial-section {
  text-align: center;
}




.testimonial-container {
  width: 80%;
  margin: 50px auto;
  text-align: center;
  padding: 50px 0;
}

.testimonial-title {
  font-size: 40px;
  margin-bottom: 20px;
  color: #2973ab;
}

.highlight {
  color: #2973ab;
  /* Blue color */
  font-weight: bold;
}

.testimonial-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
}

.testimonial-box {
  position: relative;
  background-color: #fff;
  border: 1px solid #e57438;
  padding: 40px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.testimonial-box-side {
  position: relative;
  z-index: 1;
}


.testimonial-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: -7px;
  height: 30%;
  width: 7px;
  background-color: #e57438;
}

/* Vertical orange line on bottom-right */
.testimonial-box::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -7px;
  height: 30%;
  width: 7px;
  background-color: #e57438;
}

.testimonial-box-side::before {
  content: '';
  position: absolute;
  top: -48px;
  left: -47px;
  width: 72px;
  height: 8px;
  background-color: #e57438;
}

.testimonial-box-side::after {
  content: '';
  position: absolute;
  bottom: -46px;
  right: -47px;
  width: 75px;
  height: 7px;
  background-color: #e57438;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-arrow {
  width: 40px !important;
  margin: 20px 0px;
}

.quote {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
}

.author {
  font-size: 20px;
  color: #424242;
  font-weight: bold;
}

.arrow {
  font-size: 50px;
  color: #2973ab;
  cursor: pointer;
  z-index: 2;
}

.left-arrow {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.right-arrow {
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow:hover {
  color: #2973ab;
}

/* Responsive adjustments */
@media (max-width: 991px) {
  .testimonial-box {
    width: 100%;
  }

  .left-arrow,
  .right-arrow {
    font-size: 20px;
    left: -30px;
    right: -30px;
  }
}

.customer-title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
  color: #2973ab;
}

.top-btn {
  background-color: #064575 !important;
}

.footer-links h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

.footer-links h3 {
  position: relative;
  /* Ensures proper positioning of the ::before element */
  padding-left: 15px;
  /* Adds space for the blue line */

}

.footer-links h3::before {
  content: '';
  /* Mandatory for ::before to appear */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* Full height of the heading */
  width: 2px;
  /* Width of the blue line */
  background-color: #30afee;
  /* Blue color */

}

.footer-contact h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .card-content {
    left: 30px;
    right: 30px;
  }

  .navbar-nav .nav-item {
    padding: 10px 20px !important;
  }

  .navbar-nav .nav-item:after {
    display: none;
  }

  .login-btn .demo-button {
    display: none !important;
  }

  .navbar-nav .nav-item .book-demo {
    display: flex;
  }

  .desktop-only {
    display: none !important;
  }

  .preview-table-stepper>div>span {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
  }

}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.329);
  /* Dark background with opacity */
  backdrop-filter: blur(5px);
  /* Blur effect for the background */
}

.modal-content {
  background-color: #ffffff;
  /* White background for the modal */
  margin: 5% auto;
  /* Centered with a margin from the top */
  padding: 30px;
  border-radius: 10px;
  /* Rounded corners */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* Soft shadow effect */
  width: 90%;
  /* Responsive width */
  max-width: 1100px;
  /* Maximum width */
  animation: slideIn 0.2s ease;
  /* Animation effect */
}

@keyframes slideIn {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal h2 {
  color: #333;
  /* Darker color for the heading */
  margin-top: 0;
  /* Remove top margin */
}

.modalp {
  color: #555;
  /* Grey color for the paragraph text */
  line-height: 1.6;
  /* Improved line height for readability */
}

.close {
  color: #2973ab;
  /* Light grey for the close button */
  float: right;
  display: flex;
  justify-content: flex-end;
  font-size: 40px;
  opacity: 1 !important;
  font-weight: 100;
}

.close:hover,
.close:focus {
  color: #000;
  /* Darker color on hover */
  text-decoration: none;
  cursor: pointer;
}

.close2 {
  color: #2973ab;
  /* Light grey for the close button */
  float: right;
  font-size: 40px;
  font-weight: 100;
  display: flex;
  opacity: 1 !important;
  justify-content: flex-end;

}

.close2:hover,
.close2:focus {
  color: #000;
  /* Darker color on hover */
  text-decoration: none;
  cursor: pointer;
}

.modal button {
  background-color: #e57438;
  /* Primary color for the button */
  color: white;
  /* White text color */
  border: none;
  /* Remove border */
  padding: 10px 15px;
  /* Padding for the button */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: background-color 0.3s;
  /* Transition for background change */
}

.modal button:hover {
  background-color: #e57438;
  /* Darker shade on hover */
}

.slick-slide {
  margin: 10px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.testimonial-slider {
  padding: 20px;
}

.slick-slide img {
  display: block;
}

.slick-slide img:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slick slider dots */
.slick-dots {
  position: absolute;
  bottom: -30px;
  /* Adjust this value to control vertical positioning */
  display: none !important;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 50px;
  /* Dot size */
  height: 3px;
  /* Dot size */
  padding: 0px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #ccc;
  /* Default dot color */

  transition: background 0.3s ease;
  /* Smooth transition on hover */
}

.slick-dots li.slick-active button {
  background: #2973ab;
  /* Active dot color */
}

.slick-dots li:hover button {
  background: #333;
  /* Color on hover */
}



/* Style for the left arrow */
.slick-prev {
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: none;
  color: #2973ab;
  font-size: 50px;
  cursor: pointer;
  transform: translateY(-50%);
}

.slick-prev:hover {
  background-color: rgba(0, 0, 0, 0);
}

/* Style for the right arrow */
.slick-next {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(105, 18, 163, 0);
  border-radius: 50%;
  color: #2973ab;
  border: none;
  font-size: 50px;
  cursor: pointer;
  transform: translateY(-50%);
}

.slick-next:hover {
  background-color: rgba(0, 0, 0, 0);
}


.custom-dots {
  display: flex;
  justify-content: center;
  /* Center dots horizontally */
}

.custom-dots .dot {
  width: 50px;
  height: 3px;
  background-color: #ccc;
  margin: 0 5px;
  /* Spacing between dots */
  cursor: pointer;
  /* Pointer cursor on hover */
}

.custom-dots .dot.active {
  background-color: #2973ab;
  /* Color for the active dot */
}

.domain-scan-container {
  display: flex;
  width: 100%;
  /* Adjust container width */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid #30afee;
  border-radius: 4px;
  overflow: hidden;
}

.input-icon-wrapper {
  position: relative;
  flex: 1;
}

.input-icon-wrapper i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  /* Icon color */
  font-size: 16px;
}

.domain-input {
  width: 100%;
  padding: 10px 15px 10px 35px;
  /* Space for the icon */
  border: none;
  font-size: 16px;
  outline: none;
}

.domain-input::placeholder {
  color: #999;
  /* Placeholder text color */
}

.scan-button {
  background-color: #30afee;
  /* Dark background for the button */
  color: #fff;
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scan-button:hover {
  background-color: #00212d;
  /* Slightly darker on hover */
}

.containernew {
  display: flex;
  justify-content: space-between;
  /* Spread images evenly */
  flex-wrap: wrap;
  /* Wrap on smaller screens */
  padding: 0;
  /* No padding for full-width alignment */
  width: 100%;
  /* Full width */
}

.containernew img {

  max-width: 100%;
  /* Ensure it scales within the flexbox */
  height: auto;
  /* Maintain aspect ratio */
  margin: 5px;
  /* Small space between images */
  border-radius: 8px;
  /* Optional: Rounded corners */
  object-fit: contain;
  /* Fit images without stretching */

}

/* Responsive Design */
@media (max-width: 768px) {
  .containernew img {
    flex: 1 1 calc(33.33% - 10px);
    /* 3 images per row */
  }
}

@media (max-width: 480px) {
  .containernew img {
    flex: 1 1 calc(50% - 10px);
    /* 2 images per row */
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  padding-top: 60px;

}

.step {
  margin: -50px 0px;
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.step-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.step-img {
  width: 50%;
  max-width: 400px;
  border-radius: 10px;
}

.step-text {
  flex: 1;
  padding: 70px;
  text-align: left;
}

.step h2 {
  color: #30afee;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.step p {
  font-size: 19px;
  font-weight: 600;
  /* color: #000; */
}

/* Reverse Step */
.reverse .step-content {
  flex-direction: row-reverse;
}

.curve-line {
  position: relative;
  /* Change to relative */
  display: block;
  /* Ensure it takes full width of the container */
  margin: -20px auto 0;
  /* Move it upwards slightly and center it */

  z-index: 1;
  /* Place curve line behind the steps */
}

/* Responsive Design */
@media (max-width: 768px) {
  .step-content {
    flex-direction: column;
    text-align: center;
  }

  .independent-dropdown.hide {
    display: none !important;
  }


  .reverse .step-content {
    flex-direction: column;
  }

  .step-img {
    width: 100%;
  }

  .curve-line {
    width: 60px;
    /* Smaller curve lines for mobile */
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

}

a:hover {
  color: #fff;
  text-decoration: none;
}

.whyp {
  font-size: 20px;
}

.newbg {
  padding: 0px;
}

.techBg {
  padding: 130px 0px;
}

.login-btn {
  display: flex;
  gap: 10px;
}

.login-btn .demo-button {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  width: fit-content;
  font-size: 12px;
  border-radius: 5px;
  background-color: #229fdd;
  color: #ffffff;
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  .newbg {
    background: url(../public/assets/images/SG_landing_woman3_mobile.webp) no-repeat center center / cover !important;
    padding: 0px 0px 200px 0px;
  }

  .techBg {
    background: url(../public/assets/images/SG_man_woman_desk_hero2.webp) no-repeat center center / cover !important;
    padding: 0px 0px 400px 0px;
  }
}

@media only screen and (max-width: 490px) {
  .preview-table-actions {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .login-btn {
    display: flex
  }

  .buttons-container {

    margin-top: 20px;
  }

  .navbar {
    padding: 5px !important;
  }

  .learn-more {
    max-width: 80px;
    margin-right: 5px;
    padding: 11px 10px !important;
    font-size: 10px
  }

  .online-text {
    font-size: 18px !important;
  }

  .hero-text h3 {
    font-size: 28px;
    font-weight: 600;
  }

  .hero-text p {
    font-size: 16px;
  }

  .domain-input {
    font-size: 14px !important;
    line-height: 25px;
  }

  .scan-button {
    font-size: 16px !important;
  }

  .about-content {
    padding-left: 20px;
  }

  .about-content p {
    font-size: 18px !important;
  }

  .about-content .learn-more-btn {
    width: 130px !important;
  }

  .whyp {
    font-size: 18px;
  }

  .step-text {

    padding: 10px
  }

  .step {
    margin: 10px 0px !important;
  }

  .step p {
    font-size: 18px;
  }

  .contact-us-left p {
    font-size: 18px;
  }

  footer {
    padding: 60px 10px 10px 10px;
  }

  .contact-us-left h1 {
    font-size: 30px !important;
  }

  .contact-us-content {
    padding: 100px 20px 30px 20px;
    margin-top: 100px;
  }

  .why-choose-section {
    padding: 50px 20px 0px 20px;
  }
}

.contact-form {
  /* max-width: 600px; */
  margin: 0 auto;
}

.contact-us-buttons {
  display: flex;
  gap: 15px;
}

.contact-us-buttons .demo-button{
  margin: 0;
  background-color: #0f2733;
  border: 2px solid #30afee
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.form-group {
  position: relative;
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

.input-icon {

  font-weight: 400;
  padding: 10px 15px 10px 35px;
  /* Add space for the icon */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group .icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #999;
}

textarea.input-icon {
  resize: vertical;
  height: 212px;
}