@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F7F8FB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --pagination-color: #30afee;
  --pagination-hover-color: #30afee;
  /* etc */
}

.bg-header_bg {
  background-image: url(./assets/images/header_bg.png);
  background-size: contain;
}

.bg-hero1 {
  background-image: url(./assets/images/home_hero1.png);
  background-repeat: no-repeat;
  /* background-size: contain; */
}

.bg-moreaboutus {
  background-image: url(./assets/images/shape_aboutus.png);
  background-repeat: no-repeat;
  background-position: right top;
}

.bg-cardshape {
  background-image: url(./assets/images/card_shape.png);
  background-repeat: no-repeat;
  background-position: left top;
}

.bg-auth {
  background-image: url(./assets/images/auth.png);
}

.react-datepicker-wrapper {
  width: 100%;
}

.technology_shape1 {
  background-image: url(./assets/images/shape_aboutus.png);
  background-repeat: no-repeat;
  background-position: right top;
}

.technology_shape2 {
  background-image: url(./assets/images/technology_shape.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}

section h2 {
  font-weight: 700;
}

.row-title-text {
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/font/static/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"),
    url(./assets/font/static/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: local("Montserrat"),
    url(./assets/font/static/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  src: local("Montserrat"),
    url(./assets/font/static/Montserrat-Black.ttf) format("truetype");
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #e5e7eb;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  /* border-bottom: none; */
  border-right: none;
  border-left: none;
  border-top: none;
  bottom: -30px;
  /* position: relative; */
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  color: #30afee;
  border-color: #30afee;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  /* color: GrayText; */
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

._2_g61 {
  padding: 0px !important;
}

._2Jtxm._35Ago ._2kL0S {
  background-color: #30afee !important;
}

._hsN1w:hover {
  background-color: #30afee !important;
}

._hsN1w {
  background-color: #30afee !important;
  border-color: #30afee !important;
}

input:focus-visible {
  border-color: grey !important;
}

input:focus {
  border-color: grey;
}

.dashboardlayout {
  margin: 0px !important;
  display: flex !important;
  height: 100vh;
  max-width: 300px !important;
  padding: 0px !important;
}

.homeheader {
  margin: 0px !important;
  display: flex !important;
  height: 50vh;
  padding: 0px !important;
}

@layer components {
  .submenu {
    @apply overflow-hidden transition-all duration-300 ease-in-out;
  }

  .submenu-open {
    @apply max-h-screen;
  }
}

.custom-scroll {
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: var(--pagination-color);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: var(--pagination-color);
}

.actions {
  display: flex;
  gap: 10px;
}

.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid rgb(228, 228, 231);
  border-radius: 5px;
  width: fit-content;
  color: rgb(9, 9, 11)
}

.custom-button.icon {
  width: 35px;
  height: 35px;
  padding: 0;
}

.custom-pill {
  display: flex;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  border: 1px solid rgb(228, 228, 231);
  border-radius: 30px;
  width: fit-content;
  color: rgb(9, 9, 11);
}

.MuiTooltip-popper {
  * {
    font-size: 13px !important;
  }
}

.custom-expand-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  border-radius: 50%;
  padding: 5px;
  margin-top: -15px;

  cursor: pointer;
}

.custom-expand-arrow.closed {
  right: -12px;
  margin-top: 0;
}

.custom-expand-arrow svg {
  width: 15px;
  height: 15px;
}

.analysis-table table {
  border-collapse: separate !important;
}

@media screen and (max-width: 1440px) {


  .analysis-table table, .monitor-table table {
    table-layout: fixed;
  }

  .analysis-table tr td:nth-child(2) a, .monitor-table tr td:nth-child(2) a {
    word-break: break-all;
    white-space: normal;
  }

  .analysis-table tr td:nth-child(3) {
    white-space: pre-wrap;
    word-break: keep-all;
  }

  .analysis-table tr td:nth-child(4) {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .analysis-table tr td:nth-child(5) {
    word-break: break-all;
    white-space: normal;
  }

  .analysis-table tr td:nth-child(6) {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}