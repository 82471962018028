.common-style-first {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.5rem;
    font-weight: 500;
        font-size: 0.875rem;
}

.common-style-second {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
}

.common-style-third {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    background-color: #1565c0;
    background: #1565c0;
}

.custom-css-hover-gray:hover {
background: rgba(0, 0, 0, 0.04) !important;
}


.customModernMenuNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* or whatever height you need */
}

.customModernMenuNav > div:nth-last-of-type(2) {
  margin-top: auto;
      border-top: 0.5px solid rgb(51 51 51 / 35%);
    padding-top: 5px;
}

.customModernMenuNav > div:last-of-type {
  margin-top: 0;
  margin-bottom: 20px;
}