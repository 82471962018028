.technology-section-third {
	padding: 40px 0 55px;
}

.feature-tabs-css-old,
.process-flow-css,
.methods-slide-css,
.monitoring-css {
	/* Responsive Design */
	/* Responsive Design */
	/* Responsive Design */
	/* Desktop Tabs */
	/* Mobile Accordion */
}
.feature-tabs-css-old .step,
.process-flow-css .step,
.methods-slide-css .step,
.monitoring-css .step {
	margin: -50px 0px;
	width: 100%;
	max-width: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.feature-tabs-css-old .slider-container,
.process-flow-css .slider-container,
.methods-slide-css .slider-container,
.monitoring-css .slider-container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 2rem 1rem;
}
.feature-tabs-css-old .slider-wrapper,
.process-flow-css .slider-wrapper,
.methods-slide-css .slider-wrapper,
.monitoring-css .slider-wrapper {
	position: relative;
	background: linear-gradient(135deg, #2b7da5 0%, #1c5270 100%);
	border-radius: 12px;
	padding: 2rem;
	overflow: hidden;
}
.feature-tabs-css-old .slider-content,
.process-flow-css .slider-content,
.methods-slide-css .slider-content,
.monitoring-css .slider-content {
	display: flex;
	transition: transform 0.5s ease-in-out;
	width: 100%;
}
.feature-tabs-css-old .slide,
.process-flow-css .slide,
.methods-slide-css .slide,
.monitoring-css .slide {
	flex: 0 0 100%;
	width: 100%;
}
.feature-tabs-css-old .slide-inner,
.process-flow-css .slide-inner,
.methods-slide-css .slide-inner,
.monitoring-css .slide-inner {
	background: rgba(255, 255, 255, 0.1);
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
	border-radius: 8px;
	padding: 2rem;
	color: white;
	margin: 0 1rem;
	height: 280px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.feature-tabs-css-old .step-indicator,
.process-flow-css .step-indicator,
.methods-slide-css .step-indicator,
.monitoring-css .step-indicator {
	display: inline-block;
	background-color: #33b9ff;
	color: white;
	padding: 0.5rem 1rem;
	border-radius: 20px;
	font-size: 0.875rem;
	margin-bottom: 1rem;
}
.feature-tabs-css-old .slide h3,
.process-flow-css .slide h3,
.methods-slide-css .slide h3,
.monitoring-css .slide h3 {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 1rem;
}
.feature-tabs-css-old .slide p,
.process-flow-css .slide p,
.methods-slide-css .slide p,
.monitoring-css .slide p {
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.6;
}
.feature-tabs-css-old .nav-button,
.process-flow-css .nav-button,
.methods-slide-css .nav-button,
.monitoring-css .nav-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background: rgba(51, 185, 255, 0.1);
	border: none;
	color: white;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s ease;
}
.feature-tabs-css-old .nav-button:hover,
.process-flow-css .nav-button:hover,
.methods-slide-css .nav-button:hover,
.monitoring-css .nav-button:hover {
	background: rgba(51, 185, 255, 0.2);
}
.feature-tabs-css-old .nav-button.prev,
.process-flow-css .nav-button.prev,
.methods-slide-css .nav-button.prev,
.monitoring-css .nav-button.prev {
	left: 1rem;
}
.feature-tabs-css-old .nav-button.next,
.process-flow-css .nav-button.next,
.methods-slide-css .nav-button.next,
.monitoring-css .nav-button.next {
	right: 1rem;
}
.feature-tabs-css-old .dots-container,
.process-flow-css .dots-container,
.methods-slide-css .dots-container,
.monitoring-css .dots-container {
	position: absolute;
	bottom: 1rem;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 0.5rem;
}
.feature-tabs-css-old .dot,
.process-flow-css .dot,
.methods-slide-css .dot,
.monitoring-css .dot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.5);
	border: none;
	cursor: pointer;
	padding: 0;
	transition: all 0.3s ease;
}
.feature-tabs-css-old .dot.active,
.process-flow-css .dot.active,
.methods-slide-css .dot.active,
.monitoring-css .dot.active {
	background: #33b9ff;
	width: 16px;
	border-radius: 4px;
}
@media (max-width: 768px) {
	.feature-tabs-css-old .slider-wrapper,
	.process-flow-css .slider-wrapper,
	.methods-slide-css .slider-wrapper,
	.monitoring-css .slider-wrapper {
		padding: 1rem;
	}
	.feature-tabs-css-old .slide-inner,
	.process-flow-css .slide-inner,
	.methods-slide-css .slide-inner,
	.monitoring-css .slide-inner {
		padding: 1.5rem;
		margin: 0 0.5rem;
	}
	.feature-tabs-css-old .nav-button,
	.process-flow-css .nav-button,
	.methods-slide-css .nav-button,
	.monitoring-css .nav-button {
		width: 36px;
		height: 36px;
	}
}
@media (max-width: 480px) {
	.feature-tabs-css-old .slide h3,
	.process-flow-css .slide h3,
	.methods-slide-css .slide h3,
	.monitoring-css .slide h3 {
		font-size: 1.25rem;
	}
	.feature-tabs-css-old .nav-button,
	.process-flow-css .nav-button,
	.methods-slide-css .nav-button,
	.monitoring-css .nav-button {
		width: 32px;
		height: 32px;
	}
}
.feature-tabs-css-old .monitoring-container,
.process-flow-css .monitoring-container,
.methods-slide-css .monitoring-container,
.monitoring-css .monitoring-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 4rem 1rem;
}
.feature-tabs-css-old .monitoring-title,
.process-flow-css .monitoring-title,
.methods-slide-css .monitoring-title,
.monitoring-css .monitoring-title {
	text-align: center;
	color: #2b7da5;
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 3rem;
}
.feature-tabs-css-old .monitoring-grid,
.process-flow-css .monitoring-grid,
.methods-slide-css .monitoring-grid,
.monitoring-css .monitoring-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;
}
.feature-tabs-css-old .feature-card,
.process-flow-css .feature-card,
.methods-slide-css .feature-card,
.monitoring-css .feature-card {
	background: white;
	border-radius: 12px;
	padding: 2rem;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	box-shadow: 0 4px 6px rgba(43, 125, 165, 0.1);
	box-shadow: 0 8px 15px rgba(43, 125, 165, 0.2);
}
.feature-tabs-css-old .feature-icon,
.process-flow-css .feature-icon,
.methods-slide-css .feature-icon,
.monitoring-css .feature-icon {
	background: linear-gradient(135deg, #2b7da5 0%, #1c5270 100%);
	width: 50px;
	height: 50px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1.5rem;
	font-size: 1.5rem;
}
.feature-tabs-css-old .feature-title,
.process-flow-css .feature-title,
.methods-slide-css .feature-title,
.monitoring-css .feature-title {
	color: #2b7da5;
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 1rem;
	color: #30afee !important;
	font-size: 1.65rem !important;
	margin-bottom: 10px !important;
}
.feature-tabs-css-old .feature-description,
.process-flow-css .feature-description,
.methods-slide-css .feature-description,
.monitoring-css .feature-description {
	color: #666;
	line-height: 1.6;
	font-size: 1rem;
	font-weight: 400;
	color: rgb(51, 51, 51);
	margin: 20px 0;
	font-size: 18.75px;
	line-height: 1.8;
}
@media (max-width: 768px) {
	.feature-tabs-css-old .monitoring-grid,
	.process-flow-css .monitoring-grid,
	.methods-slide-css .monitoring-grid,
	.monitoring-css .monitoring-grid {
		grid-template-columns: 1fr;
	}
	.feature-tabs-css-old .monitoring-title,
	.process-flow-css .monitoring-title,
	.methods-slide-css .monitoring-title,
	.monitoring-css .monitoring-title {
		font-size: 2rem;
		margin-bottom: 2rem;
	}
	.feature-tabs-css-old .feature-card,
	.process-flow-css .feature-card,
	.methods-slide-css .feature-card,
	.monitoring-css .feature-card {
		padding: 1.5rem;
	}
}
@media (max-width: 480px) {
	.feature-tabs-css-old .monitoring-title,
	.process-flow-css .monitoring-title,
	.methods-slide-css .monitoring-title,
	.monitoring-css .monitoring-title {
		font-size: 1.75rem;
	}
	.feature-tabs-css-old .feature-title,
	.process-flow-css .feature-title,
	.methods-slide-css .feature-title,
	.monitoring-css .feature-title {
		font-size: 1.1rem;
	}
	.feature-tabs-css-old .feature-description,
	.process-flow-css .feature-description,
	.methods-slide-css .feature-description,
	.monitoring-css .feature-description {
		font-size: 0.9rem;
	}
}
.feature-tabs-css-old .process-container,
.process-flow-css .process-container,
.methods-slide-css .process-container,
.monitoring-css .process-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 4rem 1rem;
}
.feature-tabs-css-old .process-flow,
.process-flow-css .process-flow,
.methods-slide-css .process-flow,
.monitoring-css .process-flow {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 3rem;
}
.feature-tabs-css-old .process-step,
.process-flow-css .process-step,
.methods-slide-css .process-step,
.monitoring-css .process-step {
	position: relative;
	padding: 0 1rem;
}
.feature-tabs-css-old .step-content,
.process-flow-css .step-content,
.methods-slide-css .step-content,
.monitoring-css .step-content {
	background: white;
	border-radius: 12px;
	padding: 2rem;
	box-shadow: 0 4px 6px rgba(51, 185, 255, 0.1);
	display: flex;
	align-items: center;
	gap: 2rem;
	position: relative;
	z-index: 2;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	box-shadow: 0 8px 15px rgba(51, 185, 255, 0.2);
	display: flex;
	align-items: center;
	gap: 2rem;
	position: relative;
	z-index: 2;
	transition: transform 0.3sease, box-shadow 0.3sease;
	box-shadow: 7px 12px 16px -3px rgba(20, 82, 114, 0.45);
}
.feature-tabs-css-old .step-icon,
.process-flow-css .step-icon,
.methods-slide-css .step-icon,
.monitoring-css .step-icon {
	background: linear-gradient(135deg, #33b9ff 0%, #2b7da5 100%);
	width: 82px;
	height: 82px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	color: white;
}
.feature-tabs-css-old .step-text,
.process-flow-css .step-text,
.methods-slide-css .step-text,
.monitoring-css .step-text {
	flex: 1;
}
.feature-tabs-css-old .step-text h3,
.process-flow-css .step-text h3,
.methods-slide-css .step-text h3,
.monitoring-css .step-text h3 {
	color: #2b7da5;
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 1rem;
	text-transform: uppercase;
	color: #30afee !important;
	font-size: 1.8rem !important;
	margin-bottom: 10px !important;
}
.feature-tabs-css-old .step-text p,
.process-flow-css .step-text p,
.methods-slide-css .step-text p,
.monitoring-css .step-text p {
	color: #666;
	line-height: 1.6;
}
.feature-tabs-css-old .progress-bars,
.process-flow-css .progress-bars,
.methods-slide-css .progress-bars,
.monitoring-css .progress-bars {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-left: 2rem;
}
.feature-tabs-css-old .progress-bar,
.process-flow-css .progress-bar,
.methods-slide-css .progress-bar,
.monitoring-css .progress-bar {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}
.feature-tabs-css-old .progress-dot,
.process-flow-css .progress-dot,
.methods-slide-css .progress-dot,
.monitoring-css .progress-dot {
	width: 12px;
	height: 12px;
	background: #33b9ff;
	border-radius: 50%;
}
.feature-tabs-css-old .progress-line,
.process-flow-css .progress-line,
	methods-slide-css .progress-line,
	monitoring-css .progress-line {
	height: 4px;
	background: #33b9ff;
	flex: 1;
	border-radius: 2px;
}
.feature-tabs-css-old .flow-arrow,
	process-flow-css .flow-arrow,
	methods-slide-css .flow-arrow,
	monitoring-css .flow-arrow {
	position: absolute;
	bottom: -3rem;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	height: 3rem;
}
.feature-tabs-css-old .arrow-line,
	process-flow-css .arrow-line,
	methods-slide-css .arrow-line,
	monitoring-css .arrow-line {
	width: 2px;
	flex: 1;
	background: linear-gradient(to bottom, #33b9ff 50%, transparent 50%);
	background-size: 4px 4px;
	animation: flowDown 1s linear infinite;
}
.feature-tabs-css-old .arrow-icon,
	process-flow-css .arrow-icon,
	methods-slide-css .arrow-icon,
	monitoring-css .arrow-icon {
	color: #33b9ff;
	animation: bounce 2s ease-in-out infinite;
}
@keyframes flowDown {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 0 8px;
	}
}
@keyframes bounce {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(4px);
	}
}
@media (max-width: 768px) {
	.feature-tabs-css-old .step-content,
	.process-flow-css .step-content,
	.methods-slide-css .step-content,
	.monitoring-css .step-content {
		flex-direction: column;
		padding: 1.5rem;
		gap: 1rem;
	}
	.feature-tabs-css-old .step-icon,
	.methods-slide-css .step-icon,
	.monitoring-css .step-icon {
		width: 40px;
		height: 40px;
	}
	.feature-tabs-css-old .progress-bars,
	.process-flow-css .progress-bars,
	.methods-slide-css .progress-bars,
	.monitoring-css .progress-bars {
		margin-left: 0;
		margin-top: 1rem;
	}
	.feature-tabs-css-old .step-text h3,
	.process-flow-css .step-text h3,
	.methods-slide-css .step-text h3,
	.monitoring-css .step-text h3 {
		font-size: 1.1rem;
	}
	.feature-tabs-css-old .flow-arrow,
	.process-flow-css .flow-arrow,
	.methods-slide-css .flow-arrow,
	.monitoring-css .flow-arrow {
		bottom: -2.5rem;
		height: 2.5rem;
	}
}
@media (max-width: 480px) {
	.feature-tabs-css-old .process-container,
	.process-flow-css .process-container,
	.methods-slide-css .process-container,
	.monitoring-css .process-container {
		padding: 2rem 0.5rem;
	}
	.feature-tabs-css-old .step-content,
	.process-flow-css .step-content,
	.methods-slide-css .step-content,
	.monitoring-css .step-content {
		padding: 1rem;
	}
	.feature-tabs-css-old .step-text h3,
	.process-flow-css .step-text h3,
	.methods-slide-css .step-text h3,
	.monitoring-css .step-text h3 {
		font-size: 1rem;
	}
	.feature-tabs-css-old .step-text p,
	.process-flow-css .step-text p,
	.methods-slide-css .step-text p,
	.monitoring-css .step-text p {
		font-size: 0.9rem;
	}
}
.feature-tabs-css-old .features-container,
.process-flow-css .features-container,
.methods-slide-css .features-container,
.monitoring-css .features-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 4rem 1rem;
}
.feature-tabs-css-old .features-tabs,
.process-flow-css .features-tabs,
.methods-slide-css .features-tabs,
.monitoring-css .features-tabs {
	display: flex;
	gap: 2rem;
	display: none;
}
@media (min-width: 768px) {
	.feature-tabs-css-old .features-tabs,
	.process-flow-css .features-tabs,
	.methods-slide-css .features-tabs,
	.monitoring-css .features-tabs {
		display: flex;
	}
	.feature-tabs-css-old .features-accordion,
	.process-flow-css .features-accordion,
	.methods-slide-css .features-accordion,
	.monitoring-css .features-accordion {
		display: none;
	}
}
.feature-tabs-css-old .tab-list,
.process-flow-css .tab-list,
.methods-slide-css .tab-list,
.monitoring-css .tab-list {
	display: flex;
	flex-direction: column;
	width: 300px;
	gap: 1.35rem;
}
.feature-tabs-css-old .tab-button,
.process-flow-css .tab-button,
.methods-slide-css .tab-button,
.monitoring-css .tab-button {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
	background: none;
	border: none;
	border-radius: 8px;
	color: #666;
	cursor: pointer;
	text-align: left;
	transition: all 0.3s ease;
	width: 100%;
}
.feature-tabs-css-old .tab-button span,
.process-flow-css .tab-button span,
.methods-slide-css .tab-button span,
.monitoring-css .tab-button span {
	font-size: 0.9rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.feature-tabs-css-old .tab-button:hover,
.process-flow-css .tab-button:hover,
.methods-slide-css .tab-button:hover,
.monitoring-css .tab-button:hover {
	background: rgba(51, 185, 255, 0.1);
	color: #2b7da5;
}
.feature-tabs-css-old .tab-button.active,
.process-flow-css .tab-button.active,
.methods-slide-css .tab-button.active,
.monitoring-css .tab-button.active {
	background: #33b9ff;
	color: white;
}
.feature-tabs-css-old .tab-content,
.process-flow-css .tab-content,
.methods-slide-css .tab-content,
.monitoring-css .tab-content {
	flex: 1;
	background: white;
	border-radius: 12px;
	box-shadow: 0 4px 6px rgba(51, 185, 255, 0.1);
	overflow: hidden;
	align-content: center;
}
.feature-tabs-css-old .tab-panel,
.process-flow-css .tab-panel,
.methods-slide-css .tab-panel,
.monitoring-css .tab-panel {
	display: none;
	padding: 2rem;
}
.feature-tabs-css-old .tab-panel.active,
.process-flow-css .tab-panel.active,
	methods-slide-css .tab-panel.active,
	monitoring-css .tab-panel.active {
	display: block;
	animation: fadeIn 0.3s ease;
}
.feature-tabs-css-old .feature-icon,
	process-flow-css .feature-icon,
	methods-slide-css .feature-icon,
	monitoring-css .feature-icon {
	background: linear-gradient(135deg, #33b9ff 0%, #2b7da5 100%);
	width: 48px;
	height: 48px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	margin-bottom: 1rem;
}
.feature-tabs-css-old .tab-panel h3,
	process-flow-css .tab-panel h3,
	methods-slide-css .tab-panel h3,
	monitoring-css .tab-panel h3 {
	color: #2b7da5;
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 1rem;
	color: #30afee !important;
	font-size: 1.7rem !important;
	margin-bottom: 10px !important;
}
.feature-tabs-css-old .tab-panel p,
	process-flow-css .tab-panel p,
	methods-slide-css .tab-panel p,
	monitoring-css .tab-panel p {
	color: #666;
	line-height: 1.6;
}
.feature-tabs-css-old .features-accordion,
	process-flow-css .features-accordion,
	methods-slide-css .features-accordion,
	monitoring-css .features-accordion {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.feature-tabs-css-old .accordion-item,
	process-flow-css .accordion-item,
	methods-slide-css .accordion-item,
	monitoring-css .accordion-item {
	background: white;
	border-radius: 12px;
	box-shadow: 0 4px 6px rgba(51, 185, 255, 0.1);
	overflow: hidden;
}
.feature-tabs-css-old .accordion-header,
	process-flow-css .accordion-header,
	methods-slide-css .accordion-header,
	monitoring-css .accordion-header {
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 100%;
	padding: 1rem;
	background: none;
	border: none;
	color: #2b7da5;
	cursor: pointer;
	text-align: left;
	transition: all 0.3s ease;
}
.feature-tabs-css-old .accordion-header:hover,
	process-flow-css .accordion-header:hover,
	methods-slide-css .accordion-header:hover,
	monitoring-css .accordion-header:hover {
	background: rgba(51, 185, 255, 0.1);
}
.feature-tabs-css-old .accordion-item.open .accordion-header,
	process-flow-css .accordion-item.open .accordion-header,
	methods-slide-css .accordion-item.open .accordion-header,
	monitoring-css .accordion-item.open .accordion-header {
	background: #33b9ff;
	color: white;
}
.feature-tabs-css-old .accordion-content,
	process-flow-css .accordion-content,
	methods-slide-css .accordion-content,
	monitoring-css .accordion-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease;
}
.feature-tabs-css-old .accordion-item.open .accordion-content,
	process-flow-css .accordion-item.open .accordion-content,
	methods-slide-css .accordion-item.open .accordion-content,
	monitoring-css .accordion-item.open .accordion-content {
	max-height: 500px;
	padding: 1rem;
}
.feature-tabs-css-old .accordion-content p,
	process-flow-css .accordion-content p,
	methods-slide-css .accordion-content p,
	monitoring-css .accordion-content p {
	color: #666;
	line-height: 1.6;
}
@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@media screen and (min-width: 768px) {
	div.show-desktop {
		display: block;
	}
	div.show-mobi {
		display: none;
	}
}
@media screen and (max-width: 768px) {
	div.show-desktop {
		display: none;
	}
	div.show-mobi {
		display: block;
	}
	.hero-techBG .hero-content-techBG .buttons a.demo-button,
	section.technology .buttons a.demo-button,
	section .smarter-way a.demo-button,
	section.technology .request-part .hero .hero-content a.demo-button {
		min-width: -moz-max-content;
		min-width: max-content;
		display: flex;
		margin-top: 20px;
		padding: 10px 30px;
		background-color: #30afee;
		color: #fff;
		text-decoration: none;
		border-radius: 5px;
		font-size: 15px;
		font-weight: bold;
		width: -moz-fit-content;
		width: fit-content;
		align-items: center;
		text-align: center;
	}
	h1.class-h1-40,
	.technology-section-third h1,
	.hero-content-techBG h3 {
		font-size: 30px !important;
	}
	.technology-section-third {
		padding-top: 2px !important;
		padding-bottom: 2px !important;
	}
	.methods-slide-css .slider-wrapper {
		height: 520px;
	}
	.monitoring-css .slide-inner,
	.methods-slide-css .slide-inner {
		height: 460px;
	}
}

.technology-section-third {
	padding: 20px 0 35px;
	background-color: linear-gradient(
		187deg,
		#f8f9fa 0%,
		#30afee 100%
	) !important;
	background: linear-gradient(
		187deg,
		#f8f9fa 25%,
		rgba(23, 143, 202, 0.4901960784) 160%
	);
}

.monitoring-section.monitoring-section {
	background-color: #f8f9fa;
	background: transparent !important;
}
@media only screen and (max-width: 490px) {
	.learn-more {
		max-width: -moz-max-content;
		max-width: max-content;
		margin-right: 3px;
	}
	.learn-more-new {
		padding: 10px 10px !important;
		font-size: 10px !important;
		width: auto;
	}
}

figure.animated-list-css,
span.animated-list-css,
.animated-list-css {
	margin: auto;
	display: flex;
	/* Dark mode styles */
}
figure.animated-list-css .demo-container,
span.animated-list-css .demo-container,
.animated-list-css .demo-container {
	position: relative;
	display: flex;
	height: 500px;
	width: 100%;
	flex-direction: column;
	overflow: hidden;
	padding: 0.5rem;
}
figure.animated-list-css .animated-list,
span.animated-list-css .animated-list,
.animated-list-css .animated-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}
figure.animated-list-css .animated-list-item,
span.animated-list-css .animated-list-item,
.animated-list-css .animated-list-item {
	margin: 0 auto;
	width: 100%;
	opacity: 0;
	transform: scale(0);
	animation: scaleIn 0.75s ease forwards;
	transform-origin: top;
}
@keyframes scaleIn {
	from {
		opacity: 0;
		transform: scale(0);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
figure.animated-list-css .notification,
span.animated-list-css .notification,
.animated-list-css .notification {
	position: relative;
	margin: 0 auto;
	min-height: -moz-fit-content;
	min-height: fit-content;
	width: 100%;
	max-width: 100%;
	cursor: pointer;
	overflow: hidden;
	border-radius: 1rem;
	padding: 1rem;
	background-color: white;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.05),
		0 12px 24px rgba(0, 0, 0, 0.05);
	transition: all 0.2s ease-in-out;
	box-shadow: 0 0 0 1px rgb(55, 110, 138),
		0 2px 4px rgba(67, 69, 70, 0.7607843137),
		0 12px 24px rgba(108, 205, 255, 0.1);
	transition: all 0.2sease-in-out;
}
figure.animated-list-css .notification:hover,
span.animated-list-css .notification:hover,
.animated-list-css .notification:hover {
	transform: scale(1.03);
}
figure.animated-list-css .notification-content,
span.animated-list-css .notification-content,
.animated-list-css .notification-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.75rem;
}
figure.animated-list-css .notification-icon,
span.animated-list-css .notification-icon,
.animated-list-css .notification-icon {
	display: flex;
	width: 2.5rem;
	height: 2.5rem;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
}
figure.animated-list-css .notification-icon span,
span.animated-list-css .notification-icon span,
.animated-list-css .notification-icon span {
	font-size: 1.25rem;
	font-size: 14px;
	opacity: 0.75;
}
figure.animated-list-css .notification-text,
span.animated-list-css .notification-text,
.animated-list-css .notification-text {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
figure.animated-list-css .notification figcaption,
span.animated-list-css .notification figcaption,
.animated-list-css .notification figcaption {
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: pre;
	font-size: 1.125rem;
	font-weight: 500;
}
figure.animated-list-css .notification-name,
span.animated-list-css .notification-name,
.animated-list-css .notification-name {
	font-size: 0.875rem;
}
@media (min-width: 640px) {
	figure.animated-list-css .notification-name,
	span.animated-list-css .notification-name,
	.animated-list-css .notification-name {
		font-size: 1.125rem;
	}
}
figure.animated-list-css .notification-dot,
span.animated-list-css .notification-dot,
.animated-list-css .notification-dot {
	margin: 0 0.25rem;
}
figure.animated-list-css .notification-time,
span.animated-list-css .notification-time,
.animated-list-css .notification-time {
	font-size: 0.75rem;
	color: #6b7280;
}
figure.animated-list-css .notification p,
span.animated-list-css .notification p,
.animated-list-css .notification p {
	font-size: 0.875rem;
	font-weight: normal;
	color: #666;
	font-weight: 500;
	color: rgb(51, 51, 51);
	/* margin: 20px 0; */
	font-size: 1.075rem;
	line-height: normal;
}
figure.animated-list-css .fade-overlay,
span.animated-list-css .fade-overlay,
.animated-list-css .fade-overlay {
	pointer-events: none;
	position: absolute;
	inset-inline: 0;
	bottom: 0;
	height: 25%;
	background: linear-gradient(to top, white, transparent);
}
@media (prefers-color-scheme: dark) {
	figure.animated-list-css .notification,
	span.animated-list-css .notification,
	.animated-list-css .notification {
		background-color: transparent;
		-webkit-backdrop-filter: blur(8px);
		backdrop-filter: blur(8px);
		border: 1px solid rgba(255, 255, 255, 0.1);
		box-shadow: 0 -20px 80px -20px rgba(255, 255, 255, 0.12) inset;
	}
	figure.animated-list-css .notification figcaption,
	span.animated-list-css .notification figcaption,
	.animated-list-css .notification figcaption {
		color: white;
	}
	figure.animated-list-css .notification p,
	span.animated-list-css .notification p,
	.animated-list-css .notification p {
		color: rgba(255, 255, 255, 0.3);
	}
	figure.animated-list-css .fade-overlay,
	span.animated-list-css .fade-overlay,
	.animated-list-css .fade-overlay {
		background: linear-gradient(to top, rgb(17, 17, 17), transparent);
	}
}



section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root .css-1lt5qva-MuiCardContent-root:last-child, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root .MuiCardContent-root:last-child, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation .css-1lt5qva-MuiCardContent-root:last-child, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation .MuiCardContent-root:last-child {
  padding-bottom: 24px;
  height: 100%;
  max-height: 100%;
  padding: 1.2rem;
  border-left: 3px solid rgba(54, 178, 239, 0.7411764706);
}
section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root .MuiCardContent-root > div.MuiBox-root, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation .MuiCardContent-root > div.MuiBox-root {
  align-items: center;
  gap: 16px;
  height: 100%;
}
section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root .MuiCardContent-root > div.MuiBox-root svg.MuiSvgIcon-root, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root .MuiCardContent-root > div.MuiBox-root .MuiSvgIcon-root, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation .MuiCardContent-root > div.MuiBox-root svg.MuiSvgIcon-root, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation .MuiCardContent-root > div.MuiBox-root .MuiSvgIcon-root {
  fill: rgb(49, 167, 228);
}
section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root:hover, section.risk-assessment-css .MuiPaper-root.MuiPaper-elevation:hover {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transform: translateY(0px);
}

.feature-tabs-css-old .tab-panel p, .process-flow-css .tab-panel p, .methods-slide-css .tab-panel p, .monitoring-css .tab-panel p {
  color: #333333;
}

.features-container.feature-tabs-css .features-grid, .feature-tabs-css .features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, max-content);
  justify-items: center;
  align-items: flex-start;
  gap: 5px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  grid-auto-rows: 1fr; /* This ensures equal height rows */
}
.features-container.feature-tabs-css .features-grid .feature-card, .feature-tabs-css .features-grid .feature-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(43, 125, 165, 0.1);
  box-shadow: 0 8px 15px rgba(43, 125, 165, 0.2);
  height: 100%; /* Make the card take full height of grid cell */
  display: flex;
  flex-direction: column;
}
.features-container.feature-tabs-css .features-grid .feature-card:hover, .feature-tabs-css .features-grid .feature-card:hover {
  transform: translateY(0px);
}
.features-container.feature-tabs-css .features-grid .feature-card .feature-icon, .feature-tabs-css .features-grid .feature-card .feature-icon {
  background: linear-gradient(135deg, #33b9ff 0%, #2b7da5 100%);
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  .features-container.feature-tabs-css .features-grid, .feature-tabs-css .features-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
  }
}